import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { ProdOfferingsService } from '../../services/product-offerings.service';
import { ProdTemplatesService } from '../../services/product-templates.service';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';

@Component({
  selector: 'app-view-prod-offering',
  templateUrl: './view-prod-offering.component.html',
  styleUrls: ['./view-prod-offering.component.scss'],
})
export class ViewProdOfferingComponent implements OnInit {
  options: GlobalConfig;
  offeringName:any;
  templatesTypesOptions = ['Existing + Draft','Existing only' ,'Draft only' ];
  tempTypeVal = 'Existing + Draft';
  showSearchInput = false;
  offeringReferenceId:String;
  searchOptions = {
    expandOfferings:true,
    expandParties:true
  }
  restrictions = [];
  hiddenRestrictions = ['client']
  offeringDescription: any;
  // isSingleSelct = true;
  productType: any;
  points: any;
  categoryCodes: any;
  offeringInfo:any;
  offeringIcon:any;
  defaultOfferingIcons = "../assets/images/offering_sample_img.png";
  categoryInfo = {
    displayName:null,
    colorCode:'#00EBB3',
    count:0,
    list:[]
  }
  associatedTemplates=[];
  offeringStatus: string;
  applicationsList = [];
  operatorsList = [];
  dropdownList = [];
  sub: any;
  productList: string;
  domesticSubProduct: string;
  InternationalSubProduct: string;
  domesticProductList: string;
  checkIsBB: any;
    constructor(private route: ActivatedRoute,
        private router: Router,
        public prodOfferingsService : ProdOfferingsService,
        public prodTemplatesService : ProdTemplatesService,
        public dialog: MatDialog,
        public openDialogs: MatDialogRef<any>,
        private _Activatedroute:ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public dialogData:any,
        public toastr: ToastrService ){
          this.options = this.toastr.toastrConfig;

    }

    ngOnInit(){ 
      console.log("ViewofferingComponent loads");
      console.log(this.dialogData)
      if(sessionStorage.getItem('colorCodes')){
        this.categoryCodes = JSON.parse(sessionStorage.getItem('colorCodes'));
      }
      if(sessionStorage.getItem("OfferingStatus")){
        this.offeringStatus = sessionStorage.getItem("OfferingStatus");
      }
      this.getApplicationsList();
      this.getDropDownValues();
      this.getOperatorValues(); 
      this.sub=this._Activatedroute.paramMap.subscribe(params => { 
        console.log(params);
        let referenceId = params.get('id');
        this.getOfferingInfo(referenceId);
      });
    }

    goBack () {
      this.router.navigate(['/prod-menu-list/product-offerings']);
    }

    editOffering (action) {
      sessionStorage.setItem('offeringAction',action);
      if(action == 'duplicate'){
        this.router.navigate(['/create-offering'],{ state: {data:this.offeringInfo }});
      }else{
        this.router.navigate(['/edit-offering',this.offeringReferenceId],{ state: {data:this.offeringInfo }});
      }
      // console.log("In edit offering function");
      
    }

    openDeleteModel () {
      let title = "Delete Confirmation";
      let  content = "Are you sure you want to delete this Offering? This offering will be moved to ‘Archived Offering’ .";
      let title1 = "Permanent Delete Confirmation";
      let content2 = "Are you sure you want to delete this Offering permanently? You won’t be able to restore it.";

      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title: this.offeringStatus == 'Active' ? title : title1,
          content:this.offeringStatus == 'Active' ? content : content2,
          actions:[
            {name:'Cancel',type:'close'},
            {name:'Delete',type:'delete'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
        if(action == 'delete'){
          this.prodOfferingsService.showSpinner();
          let action =   this.offeringStatus == 'Draft' ? this.prodOfferingsService.deleteOffering(this.offeringInfo.offeringDetail._id) : this.prodOfferingsService.moveToArchive(this.offeringReferenceId);
          if(action) {
            action.subscribe((response) => {
              this.prodOfferingsService.hideSpinner();
              if(response){
                this.prodOfferingsService.onChangeInOfferings();
                this.prodTemplatesService.onChangeInTemplates();
                this.goBack();
              }
            },(error)=>{
              this.prodOfferingsService.hideSpinner();
              const httpErr = error.toString().split(':')[2];
              const opt = JSON.parse(JSON.stringify(this.options));
              this.toastr.show(
                httpErr,
                'Error',
                opt
              );
            });
          }
        }
      })
    
    }

    restoreArchivedOffering () {
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Restore Confirmation",
          type:'restore',
          content:"Are you sure you want to restore this Offering? This offering will be moved to ‘Draft Offering’ .",
          actions:[
            {name:'Close',type:'close'},
            {name:'Restore',type:'restore'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
       
        if(action == 'restore'){
          this.restoreOfferingCall()
        }
      })
    }

    restoreOfferingCall(){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.restoreArchvedOffering(this.offeringReferenceId).subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response){
          this.prodOfferingsService.onChangeInOfferings();
          this.goBack();
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }

    getOfferingInfo (referenceId:string) {
      this.offeringReferenceId = referenceId ? referenceId : this.dialogData && this.dialogData.referenceId  ? this.dialogData.referenceId : undefined;
      if(this.offeringReferenceId){
        this.prodOfferingsService.showSpinner();
        this.prodOfferingsService.getOfferingByReference(this.offeringReferenceId).subscribe((response)=>{
          console.log(response);
          this.checkIsBB = this.prodOfferingsService.bbRole.getValue();
          this.prodOfferingsService.hideSpinner();
          if(response && response[0]){
              if (response[0]['offeringDetail']['icons']) {
                this.offeringIcon = response[0]['offeringDetail']['icons']['iconName'];
              }
              else {
                this.offeringIcon = this.defaultOfferingIcons;
              }
              if (response[0]['offeringDetail']['prodSubDetails']) {
                this.productList = response[0]['offeringDetail']['prodSubDetails']['intProdName'];
                this.InternationalSubProduct = response[0]['offeringDetail']['prodSubDetails']['intSubProdName'];
                this.domesticProductList = response[0]['offeringDetail']['prodSubDetails']['usProdName'];
                this.domesticSubProduct = response[0]['offeringDetail']['prodSubDetails']['usSubProdName'];
              }
            
            this.offeringInfo =  response[0];
            this.offeringName =  response[0]['offeringDetail']['displayName'];
            this.offeringStatus = response[0]['offeringDetail']['status'];
            this.offeringDescription = response[0]['offeringDetail'] &&  response[0]['offeringDetail']['description'] ? response[0]['offeringDetail']['description'] : response[0]['offeringDetail'] && response[0]['offeringDetail']['productReference'] ? response[0]['offeringDetail']['productReference']['description'] : '';
            this.restrictions = this.filterRestrictions(this.offeringInfo['restrictions']);
            this.restrictions = Array.isArray(this.restrictions) ? this.restrictions.sort((a,b) => {return a['order'] - b['order']}) : this.restrictions;
           
            if (response[0]) {
              if (response[0]['offeringDetail']['type'] && response[0]['offeringDetail']['type'] == 'Single') {
                this.productType = "Single Select";
              } else if (response[0]['offeringDetail']['type'] == 'Multiplicity' && !response[0]['offeringDetail']['singleSelectMultiplicity']) {
                this.productType = "Multi-select multiplicity";
              } else if (response[0]['offeringDetail']['type'] == 'Multiplicity' && response[0]['offeringDetail']['singleSelectMultiplicity']) {
                this.productType = "Single-select multiplicity";
              } else {
                this.productType = response[0]['offeringDetail']['type'];
              }
            }




            this.points = this.getPoints(response[0]);
            this.dispalyCategory(this.offeringInfo['offeringDetail']);
            if(this.offeringStatus == 'Active'){
              this.getTemplatesInfo(this.offeringReferenceId);
            }
          }else {
            this.offeringIcon = this.defaultOfferingIcons;
          }
        },(error)=>{
          this.prodOfferingsService.hideSpinner();
        });
      };

    }

    getPoints (offeringInfo) {
      let value:any = null;
      if(offeringInfo && offeringInfo.restrictions && offeringInfo.restrictions.length) {
        let restrictions = offeringInfo.restrictions;
        restrictions.forEach(item => {
            if(item.type && item.type ==  'Application' && item.values && item.values.includes('BenefitsBuilder')){
              value = item.points && parseInt(item.points) && parseInt(item.points) > 0 ? parseInt(item.points) : "Core";
            }
        });
      }

      return value;
    }
    filterRestrictions (restrictions) {
      let finalValue = []
      if(restrictions) {
        restrictions.forEach(element => {
          if(this.hiddenRestrictions.indexOf(element.uiType) == -1 ) {
            finalValue.push(element);
          }
        });
      }

      return finalValue;

    }
    dispalyCategory (offering){
      
      if(offering.category && Array.isArray(offering.category)){
        this.categoryInfo.list = offering.category;
        this.categoryInfo.displayName = offering.category[0];
        this.categoryInfo.count =  this.categoryInfo.list.length;
        this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')].color
      }else if(offering.category && typeof offering.category == 'string'){
        this.categoryInfo.list = offering.category.split(',');
        this.categoryInfo.displayName = this.categoryInfo.list[0];
        this.categoryInfo.count = this.categoryInfo.list.length;
        this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')].color
      }else if(!offering.category && offering.productReference){
        if(offering.productReference.category && Array.isArray(offering.productReference.category)){
          this.categoryInfo.list = offering.productReference.category;
          this.categoryInfo.displayName = offering.productReference.category[0];
          this.categoryInfo.count = offering.productReference.category.length;
          this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')].color
        }else if(offering.productReference.category && typeof offering.productReference.category == 'string'){
          this.categoryInfo.list = offering.productReference.category.split(',');
          this.categoryInfo.displayName = this.categoryInfo.list[0];
          this.categoryInfo.count = this.categoryInfo.list.length;
          this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ','')].color
        } 
      }
    }

    getTemplatesInfo (referenceVal) {
        this.prodOfferingsService.showSpinner();
        this.prodOfferingsService.getTemplatesByReference(referenceVal).subscribe((response)=>{
            this.prodOfferingsService.hideSpinner();
            if(response){
                this.associatedTemplates = response;
            }
            console.log(response);
        },(error)=>{
            this.prodOfferingsService.hideSpinner();
        })
    }

    getCreatedBy(createdByObj){
      let value = null;
      if(createdByObj && createdByObj.currentName
         && createdByObj.currentName.names && createdByObj.currentName.names.length) {
          value = createdByObj.currentName.names.toString().replace(',',' ');
      }

      return value;
    }

    closeDialog(action){
      this.openDialogs.close(action);
    }    

  getApplicationsList () {
    if(sessionStorage.getItem('OfferingApplications')){
      this.applicationsList = JSON.parse(sessionStorage.getItem('OfferingApplications'));
    }
    if(!this.applicationsList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('OfferingApplications').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          sessionStorage.setItem('OfferingApplications', JSON.stringify(response[0]['values']));
          this.applicationsList = response[0]['values'];
          // console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      });
    }
    
  }

  getOperatorValues(){
    if(sessionStorage.getItem('ScopingNumberOperators')){
      this.operatorsList = JSON.parse(sessionStorage.getItem('ScopingNumberOperators'));
    }
    if(!this.operatorsList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('ScopingNumberOperators').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          sessionStorage.setItem('ScopingNumberOperators', JSON.stringify(response[0]['values']));
          this.operatorsList = response[0]['values'];
          // console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
  }

  getDropDownValues(){
    if(sessionStorage.getItem('HomeOwnerStatus')){
      this.dropdownList = JSON.parse(sessionStorage.getItem('HomeOwnerStatus'));
    }
    if(!this.dropdownList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('HomeOwnerStatus').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          sessionStorage.setItem('HomeOwnerStatus', JSON.stringify(response[0]['values']));
          this.dropdownList = response[0]['values'];
          // console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
  }

  getRestrictionValDisplayName(type, value){
    let displayName =  value 
    let list;
    switch (type) {
      case 'application':
        list = this.applicationsList
        break;
      case 'dropdown':
        list = this.dropdownList
        break;
      case 'operator':
        list = this.operatorsList
        break;
    }
    return this.fectchDispalyValue(displayName, list);
  }

  fectchDispalyValue (displayName:string, list:any[]) {
    if(list && list.length){
      list.forEach((item)=>{
        if(item.value ==  displayName){
          displayName = item.displayValue;
        }
      })
    }
    return displayName;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

