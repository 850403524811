import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { response } from 'express';
import { ProdOfferingsService } from '../../services/product-offerings.service'
@Component({
  selector: 'app-icon-upload-modal',
  templateUrl: './icon-upload-modal.component.html',
  styleUrls: ['./icon-upload-modal.component.scss']
})
export class IconUploadModalComponent implements OnInit {
  isIconSelected: boolean = false;
  icon: any;
  searchText: any;
  expireitems: boolean = false;
  filterIconList: any = [];
  searchValue: string;
  allIconList: any = [];
  iconList: any = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IconUploadModalComponent>,
    private prodOfferService: ProdOfferingsService) { }

  ngOnInit(): void {
    this.getIcon();
  }

  getIcon() {
    this.prodOfferService.showSpinner();
    this.prodOfferService.getAllIconList().subscribe((response) => {
      if (response) {
        this.prodOfferService.hideSpinner();
        this.allIconList = response;
      }
    }, (err) => {
      this.allIconList = [];

    });
  }

  onClose(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onCancel(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  save(): void {
    this.dialogRef.close({
      action: 'dismiss',
      iconUrl: this.icon
    });
  }

  OnChangeImg(url, displayName): void {
    this.icon = { iconName: url, iconDisplayName: displayName };
    this.isIconSelected = true;
  }

  filter(query: string) {
    if (query) {
      this.filterIconList = this.allIconList.filter(customerdata => (customerdata.iconDisplayName.toLowerCase()).includes(query.toLowerCase()));
    }
  }

}
