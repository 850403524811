<div fxLayout="row" class="toaster_row" fxLayoutAlign="start">
    <div fxFlex="20%" fxLayoutAlign="center center" class="icon_backgroung" [ngClass]="{'icon_error_backgroung' : title == 'Error'}">
        <mat-icon *ngIf="title != 'Error'" style="color: #FFFFFF;">check_circle_outline</mat-icon>
        <mat-icon *ngIf="title == 'Error'" style="color: #FFFFFF;">close_circle_outline</mat-icon>

    </div>
    <div fxFlex="80%" style="color: black;">
        <div fxLayout="row" >
            <div fxFlex="90%">
                <p class="title"> {{ title }}</p>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
                <mat-icon  class="title_icon">close</mat-icon>
            </div>
        </div>
        <div fxLayout="row" >
            <div fxFlex="100%">
                <p  class="message"> {{ message }}</p>
            </div>
        </div>
    </div>
    
</div>