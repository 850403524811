import { Component, ComponentFactoryResolver, Input, OnInit, ViewContainerRef ,ViewChild, ElementRef, AfterViewInit, ViewEncapsulation, Output, EventEmitter, OnChanges, SimpleChange, forwardRef, Renderer2} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

import { ApplicationUitypeRestrictionComponent } from '../../restriction-types/application-uitype-restriction/application-uitype-restriction.component';
import { LocationUitypeRestrictionComponent } from '../..//restriction-types/location-uitype-restriction/location-uitype-restriction.component';
import { OperatorUitypeRestrictionComponent } from '../../restriction-types/operator-uitype-restriction/operator-uitype-restriction.component';
import { DropdownUitypeRestrictionComponent } from '../../restriction-types/dropdown-uitype-restriction/dropdown-uitype-restriction.component';
import { ComplexruleUitypeRestrictionComponent } from '../../restriction-types/complexrule-uitype-restriction/complexrule-uitype-restriction.component';
import { ToggleUitypeRestrictionComponent } from '../../restriction-types/toggle-uitype-restriction/toggle-uitype-restriction.component';
import { RestrictionBaseComponent } from '../../common/restriction-base.component';
import { Subscription } from 'rxjs';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-restriction-item',
  templateUrl: './restriction-item.component.html',
  styleUrls: ['./restriction-item.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => RestrictionItemComponent),
  //     multi: true
  //   },
  //   {
  //     provide: NG_VALIDATORS,
  //     useExisting: forwardRef(() => RestrictionItemComponent),
  //     multi: true
  //   }
  // ],
  encapsulation:ViewEncapsulation.None
})
export class RestrictionItemComponent implements OnInit,AfterViewInit,OnChanges {
  
  @Input() restriction:any;
  @Input() incrementalType : any;
  @Input() offeringType : any;
  @Input() disableEditOffering :boolean;
  @Input() parentForm:FormGroup;
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() restrictionChanges: EventEmitter<any> = new EventEmitter();

  @ViewChild('container', {read: ViewContainerRef}) private container: ViewContainerRef;

  readonly templateMapper = {
    application : ApplicationUitypeRestrictionComponent,
    operator : OperatorUitypeRestrictionComponent,
    location : LocationUitypeRestrictionComponent,
    complexRule : ComplexruleUitypeRestrictionComponent,
    toggle : ToggleUitypeRestrictionComponent,
    dropdown : DropdownUitypeRestrictionComponent
  };
  viewContainerRef: ViewContainerRef;
  componentFactory: any;
  componentRef: any;

  

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private cdRef:ChangeDetectorRef,
    public controlContainer: ControlContainer,
    public renderer2 : Renderer2
  ) { }

  

  ngOnInit(): void {
   
  }

  ngAfterViewInit() {
    // console.log(this.restionInfoForm);
    // console.log(this.container);
    this.viewContainerRef  = this.container;
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.restriction.value.uiType));
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(this.componentFactory);
    // this.renderer2.setAttribute(this.componentRef.location.nativeElement, 'FormArrayName','values');
    (<RestrictionBaseComponent>this.componentRef.instance).restriction = this.restriction.value;
    (<RestrictionBaseComponent>this.componentRef.instance).parentForm = this.parentForm;
    (<RestrictionBaseComponent>this.componentRef.instance).incrementalType = this.incrementalType;
    (<RestrictionBaseComponent>this.componentRef.instance).offeringType = this.offeringType;
    (<RestrictionBaseComponent>this.componentRef.instance).disableEditOffering = this.disableEditOffering;
    const remove:Subscription =(<RestrictionBaseComponent>this.componentRef.instance).remove.subscribe((event) => {
      // console.log(event);
      this.remove.emit(event)
    });
    this.componentRef.onDestroy(()=> { remove.unsubscribe(); console.log("Unsubscribing")});
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes) {
    if(changes && changes.incrementalType && changes.incrementalType.currentValue){
      const changesValue = {
        name: new SimpleChange(this.incrementalType, changes.incrementalType.currentValue, false)
      } 
    }
    if(changes && changes.offeringType && changes.offeringType.currentValue){
      const changesValue = {
        name: new SimpleChange(this.offeringType, changes.offeringType.currentValue, false)
      } 
    }
    if(this.componentRef){
      this.componentRef.instance.ngOnChanges(changes);
    }
  }

  getComponentForCardType(restrictionType) {
    return this.templateMapper[restrictionType];
  }

  // public onTouched: () => void = () => {};

  // writeValue(val: any): void {
  //   if(val){
  //     console.log(val);
  //     this.patchRestrictionForm(val);
  //     // this.restionInfoForm.get('values').patchValue(val, { emitEvent: false });
  //   }
  // }

  // registerOnChange(fn: any): void {
  //     console.log("on change");
  //    this.onChangeSub = fn;
  // }

  // registerOnTouched(fn: any): void {
  //     console.log("on blur");
  //     this.onTouched = fn;
  // }

  // setDisabledState?(isDisabled: boolean): void {
  //     isDisabled ? this.restionInfoForm.disable() : this.restionInfoForm.enable();
  // }

  // validate(c: AbstractControl): ValidationErrors | null{
  //     // console.log(this.restionInfoForm);
  //     console.log("Restriction Info validation", c);
  //     return this.restionInfoForm.valid ? null : { invalidForm: {valid: false, message: "Restriction fields are invalid"}};
  // }


  // // patchRestrictionsValue (value?:any[]) {
  // //   let restrictionsFormArray = new FormArray([],Validators.required);
  // //   if(value && value.length){
  // //     value.forEach((item)=>{
  // //       if(this.hiddenCriteriaTypes.indexOf(item.type) == -1){
  // //         restrictionsFormArray.push(this.addNewRestrictionForm(item));
  // //         // restrictionsFormArray.push(new FormControl(item));
  // //       }
  // //     })
  // //   }
  // //   return restrictionsFormArray;
  // // }

  // patchRestrictionForm (value) {
  //   this.patchRestrictionValues(value.values);
  //   if(value && Object.keys(value).length){
  //     Object.keys(value).forEach(key => {
  //       if(key != 'values'){
  //         this.restionInfoForm.addControl(key,new FormControl(value[key],Validators.required));
  //       }
  //     });
  //   }

  // }

  // patchRestrictionValues (values) {
  //   if(values && values.length){
  //     values.forEach(item => {
  //       (this.restionInfoForm.get('values') as FormArray).push(new FormControl(item,Validators.required));
  //     });
      
  //   }
  // }
}
