import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { ProdTemplatesService } from 'src/app/core/services/product-templates.service';

@Component({
  selector: 'app-add-template-model',
  templateUrl: './add-template-model.component.html',
  styleUrls: ['./add-template-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddTemplateModelComponent implements OnInit {

  selectedTemplateName:String = "Existing Templates";
  searchOptions = {
    skip:1,
    limit:20,
    status:'Active',
    searchText:'',
    onlyShowMine:false,
    sortDir:'ASC'
  };
  templatesData: any[];
  searchCount: string;
  showSearchCloseIcon: boolean;
  timeOut: any;
  selectedTemplatesList: any = [];

  @ViewChild('searchInput') searchInput;
 

  constructor(public openDialogs: MatDialogRef<any>,
  @Inject(MAT_DIALOG_DATA) public dialogData:any,
  private prodTemplatesService : ProdTemplatesService) { }

  ngOnInit(): void {
    
    if(this.dialogData.templates){
      this.selectedTemplatesList = this.dialogData.templates;
      console.log(this.selectedTemplatesList);
    }
    this.getAllTemplates();
  }

  closeDialog(action){
    this.openDialogs.close({type:action,data:this.selectedTemplatesList});
  }

  searchTemplates(){
    clearTimeout(this.timeOut);
    if(this.searchOptions.searchText && this.searchOptions.searchText.length > 2){
      this.timeOut = setTimeout(() => {
        this.showSearchCloseIcon = true;
        this.searchOptions.skip =1;
        this.templatesData = [];
        this.searchCount='0';
        this.getAllTemplates();
      }, 1000);
    }
  }

  getAllTemplates () {
    this.prodTemplatesService.showSpinner();
    this.prodTemplatesService.getAllTemplates(this.prepareTemplateSearchCriteria()).subscribe((response)=>{
      console.log(response);
      this.prodTemplatesService.hideSpinner();
      if(response && response.body.length){
        console.log(response.body);
        this.templatesData = response.body;
      }
    },(error)=>{
      this.prodTemplatesService.hideSpinner();
    });
  }

  getTemplates(type) {
    this.searchOptions.status = type;
    this.selectedTemplateName = type ==  'Active' ?  'Existing Templates' : 'Draft Templates' ;
    this.searchOptions.searchText = '';
    this.templatesData = []

    this.searchInput.nativeElement.focus();
    this.getAllTemplates();
  }

  selectedTemplates (template,action) {
    template['status'] = this.searchOptions.status == 'Active' ? 'Existing' : 'Draft';
    if(action == 'add'){
      let duplicate = false;
      this.selectedTemplatesList.forEach(element => {
        if(element._id == template._id) {
          duplicate = true;
        }
      });
      if(!duplicate){
        this.selectedTemplatesList.push(template);
      }
    }else if(action == 'remove') {
      let templateFound = false;
      let indexVal;
      this.selectedTemplatesList.forEach((element,index) => {
        if(element._id == template._id) {
          templateFound = true;
          indexVal = index;
        }
      });
      if(templateFound){
        this.selectedTemplatesList.splice(indexVal,1);
      }
    }

    console.log(this.selectedTemplatesList);
    
  }

  isSelectedTemplate(template) {
    let flag = false;
    if(template && template._id){
      this.selectedTemplatesList.forEach(element => {
        if(element._id == template._id) {
          flag = true;
        }
      });
    }
    return flag;
  }

  prepareTemplateSearchCriteria () {
    let params = this.searchOptions;
    if(params.searchText == ''){
      delete params.searchText;
    }
    return  Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  }
  
}