import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service';

import { RestrictionBaseComponent } from '../../common/restriction-base.component';


@Component({
  selector: 'app-operator-uitype-restriction',
  templateUrl: './operator-uitype-restriction.component.html',
  styleUrls: ['./operator-uitype-restriction.component.scss']
})
export class OperatorUitypeRestrictionComponent extends RestrictionBaseComponent implements OnInit {

  
  lastOperatorNotFound: boolean;
  matFocused = [];

  constructor(public prodOfferingsService : ProdOfferingsService) { 
    super(prodOfferingsService);
  }

  ngOnInit() {
    this.getOperatorValues();
  }

  matFocusedEvent(event,i,key){
    this.matFocused[i] = {};
    this.matFocused[i][key] = event;
  }

  selectedOperatorRes(Index,type?){
    if(type){
      this.matFocused[Index] = {};
      this.matFocused[Index][type] = false;
    }
    this.parentForm.get('values').get(Index+'').get('value').setValue(0);
    this.lastOperatorNotFound = false;
  }

  disableResOption(item){
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(values && values.length){
      values.forEach(element => {
        if(element.operator && element.operator == item.value) {
          flag = true;
        }
      });
    }

    return flag;
  }

  addNewOperatoresIsAlwd () {
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(!values || !values.length){
      flag = true;
    }else if(this.operatorsList && this.operatorsList.length){
      flag = this.operatorsList.length > this.restriction.values.length
    }
    return flag;
  }

  addOperatorRestriction () {
    let newRes = new FormGroup({
      "operator": new FormControl(null,Validators.required),
      "value": new FormControl(null,Validators.required)
    })
    let values = this.parentForm.get('values').value;
    let lastValIndex = (values && values.length) ? values.length -1 : -1;
    this.lastOperatorNotFound = false
    if(lastValIndex > -1 && values[lastValIndex].operator) {
      (this.parentForm.get('values') as FormArray).push(newRes)
    }else{
      this.lastOperatorNotFound = true;
    }
  }

  changeValue(type,index) {
    let value = this.parentForm.get('values').get(''+index).get('value').value;
    if(type == 'increment'){
      value++;
    }
    if(type == 'decrement' && this.parentForm.get('values').get(''+index).get('value').value > 0){
      value--;
    }
    this.parentForm.get('values').get(''+index).get('value').setValue(value);
  }

  removeOperatorRestriction (index) {
    (this.parentForm.get('values') as FormArray).removeAt(index);
  }

  addNewOperatorIsAlwd() {
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(!values || !values.length){
      flag = true;
    }else if(this.operatorsList && this.operatorsList.length){
      flag = this.operatorsList.length > values.length
    }
    return flag;
  }

  getOperatorValues(){
    if(sessionStorage.getItem('ScopingNumberOperators')){
      this.operatorsList = JSON.parse(sessionStorage.getItem('ScopingNumberOperators'));
    }
    if(!this.operatorsList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('ScopingNumberOperators').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          this.operatorsList = response[0]['values'];
          console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
    
  }

  restrictNegativeVal ($event){
    if($event.key == '-' || $event.keyCode == 189){
      $event.preventDefault();
    }
  }

}
