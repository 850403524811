<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!disableEditOffering" (click)="removeRestriction(restriction.type)">remove</mat-icon>
        </div>
    </div>
</div>
<div fxLayout="row wrap" class="resction_details_div" *ngIf="parentForm" [formGroup]="parentForm">
    <ng-container formArrayName="values">
        <div fxFlex="100" fxLayoutGap="1.25em" *ngFor="let item of parentForm?.get('values')['controls']; let i = index;let firstItem = first;let lastItem = last;">
            <div fxFlex="40" fxLayoutGap="1.25em" [formGroupName]="i">
                <div fxFlex="50"  class="resction_details_row">
                    <mat-form-field appearance="outline" class="">
                        <mat-label>Operator</mat-label>
                        <mat-select disableOptionCentering="true" formControlName="operator" (ngModelChange)="selectedOperatorRes(i)" (openedChange)="matFocusedEvent($event,i,'operator')">
                          <mat-option *ngFor="let item of operatorsList" [disabled]="disableResOption(item)" [value]="item.value">{{item.displayValue}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix *ngIf="!matFocused[i] || !matFocused[i].operator">keyboard_arrow_down</mat-icon>
                        <mat-icon matSuffix *ngIf="matFocused[i] && matFocused[i].operator">keyboard_arrow_up </mat-icon>
                    </mat-form-field>
                    <mat-error *ngIf="lastOperatorNotFound && lastItem">*Please select operator</mat-error>
                </div>
                <div fxFlex="50"  class="resction_details_row">
                    <mat-form-field appearance="outline" class="">
                        <mat-label>Number</mat-label>
                        <input type="number" min="0" matInput formControlName="value" (keydown)="restrictNegativeVal($event)" />
                        <mat-icon class="operator_down_arrow" (click)="disableEditOffering || changeValue('decrement',i)">keyboard_arrow_down</mat-icon>
                        <mat-icon class="operator_up_arrow" (click)="disableEditOffering || changeValue('increment',i)">keyboard_arrow_up</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex="20" fxLayoutAlign="start start" class="rules_field resction_details_row">
                <div fxFlex="25" *ngIf="parentForm?.get('values') && parentForm?.get('values').value && parentForm?.get('values').value.length > 1 && !disableEditOffering">
                    <div  class="row_delete_icon" fxLayoutAlign="center center">
                        <img src="../assets/icons/arrow_delete.svg" (click)="removeOperatorRestriction(i)"  alt=""/>
                    </div>
                </div>
                <div fxFlex="25" *ngIf="addNewOperatorIsAlwd() && !disableEditOffering">
                    <div *ngIf="lastItem " class="row_delete_icon" fxLayoutAlign="center center">
                        <mat-icon (click)="addOperatorRestriction()" >add</mat-icon>
                    </div>
                </div>
            </div>
            <div fxFlex="40"></div>
        </div>
    </ng-container>
</div>
