import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';

import { ProdMenuListComponent } from './core/components/product-menu-list/product-menu-list.component';
import { ProdTemplatesComponent } from './core/components/product-template/product-template.component';
import { ProdOfferingsComponent } from './core/components/product-offerings/product-offering.component';
import { ViewProdOfferingComponent } from './core/components/view-prod-offering/view-prod-offering.component';
import { ViewProdTemplateComponent } from './core/components/view-prod-template/view-prod-template.component';
import { CreateProdTemplateComponent } from './core/components/create-prod-template/create-prod-template.component';
import { EditPordTemplateComponent } from './core/components/edit-pord-template/edit-pord-template.component';
import { BaseProdOfferingComponent } from './core/components/base-prod-offering/base-prod-offering.component';
import { ExternRouteComponent } from './core/components/shared/extern-route/extern-route.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', redirectTo: 'prod-menu-list', pathMatch: 'full' },
  {
    path:'prod-menu-list',
    component: ProdMenuListComponent,
    children: [
      { path: '', redirectTo: 'product-templates', pathMatch: 'full' },
      { path: 'product-templates', component: ProdTemplatesComponent },
      { path: 'product-offerings', component: ProdOfferingsComponent }
    ]
  },
  {
    path:'view-template/:id',
    component: ViewProdTemplateComponent
  },
  {
    path:'create-template',
    component: CreateProdTemplateComponent
  },
  {
    path:'edit-template/:id',
    component: EditPordTemplateComponent
  },
  {
    path:'view-offering/:id',
    component: ViewProdOfferingComponent
  },
  {
    path:'edit-offering/:id',
    component: BaseProdOfferingComponent
  },
  {
    path:'create-offering',
    component: BaseProdOfferingComponent
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  },
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
