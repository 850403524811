import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import { ProdOfferingsService } from '../../services/product-offerings.service';
import { ViewProdOfferingComponent } from '../view-prod-offering/view-prod-offering.component';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';

@Component({
  selector: 'app-base-prod-template',
  templateUrl: './base-prod-template.component.html',
  styleUrls: ['./base-prod-template.component.scss'],
})
export class BaseProdTemplateComponent implements OnInit,OnChanges {

  showCancelBtn:boolean = true;
  showSaveDraftBtn:boolean = true;
  showPublishBtn:boolean = true;
  showSearchInput = false;
  showAddedSearchInput = false;
  searchText:any;
  searchOptions = {
    skip:1,
    status:'Active',
    searchText:'',
    limit:15
  };
  searchCount:any = '0';
  @Input() btnConfig = {
    showCancelBtn:true,
    showSaveDraftBtn:true,
    showPublishBtn:true
  };
  categoryCodes:any = {};
  availableOfferingsList = [];
  removedAvlblOfferingsLength: number = 0;
  @Input() addedOfferingsList = [];
  @Input() tempType;
  @Input() templateName;
  @Input() templateDescription;
  @Input() isDuplicateTempName;
  @Output() addOfferingEvent = new EventEmitter();
  @Output() delTempEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() saveDraftEvent = new EventEmitter();
  @Output() publishEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();
  timeOut: NodeJS.Timeout;
  templateForm: FormGroup;
  changeInAddedOfferings: boolean;
  templateInfo: any;
  dispalyDelIcon: boolean = true;
  showSearchCloseIcon :boolean = false;
  addedOfferingsSearchCount = 0;
  showAddedSearchCloseIcon : boolean = false;
  @ViewChild('tempNameField') tempNameField;
  isActive: boolean = false;

  filterCount = 0;
  filterPayload = {
    'categories': [],
    'applicationNames': [],
    'productNames': [],
    'createdBy': [],
  }
  // isFilterCleared :boolean = false;

  constructor(public route: ActivatedRoute,
      public router: Router,
      public prodOfferingsService: ProdOfferingsService,
      private fb: FormBuilder,
      public dialog: MatDialog
  ){

  }

  ngOnInit(){ 
    console.log("BaseTemplateComponent loads");
    if(sessionStorage.getItem('colorCodes')){
      this.categoryCodes = JSON.parse(sessionStorage.getItem('colorCodes'));
    }
    this.getAllOfferings();
    this.templateForm = this.fb.group({
      name:['',Validators.required],
      description:['',Validators.required]

    })
    if(this.tempType == 'create' || this.tempType == 'duplicate'){
      this.dispalyDelIcon = false;
    }
    if(this.templateName){
      this.templateName = this.tempType == 'duplicate' ? 'Copy of ' + this.templateName : this.templateName;
      this.templateForm.controls.name.patchValue(this.templateName);
    }
    if(this.templateDescription){
      this.templateForm.controls.description.patchValue(this.templateDescription);
    }
    if(this.tempType == 'edit' && sessionStorage.getItem('tempStatus') =='Active'){
      this.isActive = true;
      this.templateForm.get('name').disable();
      
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    console.log(changes);
    if(changes && changes.isDuplicateTempName && changes.isDuplicateTempName.currentValue){
      this.tempNameField.nativeElement.focus();
    }
  }

  goBack () {
    this.backEvent.emit();
  }

  getAllOfferings (search?:boolean) {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getAllOfferings(this.prepareOfferingSearchCriteria(), this.filterPayload).subscribe((response)=>{
        console.log(response);
        this.prodOfferingsService.hideSpinner();
        if(search){
          this.removedAvlblOfferingsLength = 0;
        }
        if(response && response.body.length === 0 && this.searchOptions.skip === 1 && this.filterCount > 0) {
          this.availableOfferingsList = [];
          this.searchCount = response.headers.get('x-order-count');
        }
        if(response && response.body.length){
            this.availableOfferingsList = search ? response.body: [...this.availableOfferingsList,...response.body];
            this.filterAvailbleOfferings();
            if(response.headers.get('x-order-count') ){
              this.searchCount = response.headers.get('x-order-count');
            }  
        }
    },(error)=>{
        this.prodOfferingsService.hideSpinner();
    });
  }

  prepareOfferingSearchCriteria () {
    let params = this.searchOptions;
    if (!params.searchText){
        delete params.searchText;
    }
    return  Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  }

  searchOfferings(){
    clearTimeout(this.timeOut);
    if(this.searchOptions.searchText){
      this.timeOut = setTimeout(() => {
        this.searchOptions.skip =1;
        this.availableOfferingsList = [];
        this.searchCount='0';
        this.getAllOfferings(true);
      }, 1000);
    }else{
      this.getAllOfferings(true);
    }
  }

  dispalyCategory (offering){
    let category = {
      displayName:null,
      colorCode:null,
      count:0,
      list:[]
    }
    if(offering.category && Array.isArray(offering.category)){
      category.list = offering.category
      category.displayName = offering.category[0];
      category.count =  category.list.length;
      category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
    }else if(offering.category && typeof offering.category == 'string'){
      category.list = offering.category.split(',');
      category.displayName = category.list[0];
      category.count = category.list.length;
      category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
    }else if(!offering.category && offering.productSpecificationReference){
      if(offering.productSpecificationReference[0] && offering.productSpecificationReference[0].category && Array.isArray(offering.productSpecificationReference[0].category)){
        category.list = offering.productSpecificationReference[0].category;
        category.displayName = category.list[0];
        category.count = category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(offering.productSpecificationReference[0] && offering.productSpecificationReference[0].category && typeof offering.productSpecificationReference[0].category == 'string'){
        category.list = offering.productSpecificationReference[0].category.split(',');
        category.displayName = category.list[0];
        category.count = category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(offering.productSpecificationReference.category && typeof offering.productSpecificationReference.category == 'string'){
        category.displayName = offering.productSpecificationReference.category;
        category.count = 1;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(offering.productSpecificationReference.category && Array.isArray(offering.productSpecificationReference.category)){
        category.displayName = offering.productSpecificationReference.category[0];
        category.count = offering.productSpecificationReference.category.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }
    }
    return category;
  }

  addOfferingsToList (event) {
    console.log(event);
    const element = event.target;
    let pos = (element.scrollTop || element.body.scrollTop) + element.offsetHeight + 100;
    let max =element.scrollHeight - 1;
    let maxCount = parseInt(this.searchCount) ;

    // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
    if((pos >= max) &&  (maxCount > (this.availableOfferingsList.length + this.removedAvlblOfferingsLength)))   {
        this.searchOptions.skip ++;
        window.scrollTo(0, pos - 100);
        this.getAllOfferings();
    }
  }

  addOffering (offering) {
    let maxCount = parseInt(this.searchCount) ;
    let flag = false;
    if(this.addedOfferingsList.length == 0){
      flag = true;
    }else{
      this.addedOfferingsList.forEach((item)=>{
        if(item.reference != offering.reference) {
          flag = true;
        }
      });
    }
    if(flag){
      this.changeInAddedOfferings = true;
      this.addedOfferingsList.unshift(offering);
      this.availableOfferingsList.forEach((avblOffering,index)=>{
        if(offering.reference == avblOffering.reference) {
          this.availableOfferingsList.splice(index,1);
          this.removedAvlblOfferingsLength ++;
          if(this.availableOfferingsList.length < this.searchOptions.limit &&  (maxCount > (this.availableOfferingsList.length + this.removedAvlblOfferingsLength))){
            this.searchOptions.skip ++;
            this.getAllOfferings();
          }
        }
      })
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      let maxCount = parseInt(this.searchCount);
      transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
      this.changeInAddedOfferings = true;
      this.removedAvlblOfferingsLength ++;
      if(this.availableOfferingsList.length < this.searchOptions.limit &&  (maxCount > (this.availableOfferingsList.length + this.removedAvlblOfferingsLength))){
        this.searchOptions.skip ++;
        this.getAllOfferings();
      }
    }
  }

  filterAvailbleOfferings() {
    if(this.addedOfferingsList.length){
      this.addedOfferingsList.forEach((addOffering)=>{
        this.availableOfferingsList.forEach((avblOffering,index)=>{
          if(addOffering.reference == avblOffering.reference) {
            console.log(addOffering.reference)
            console.log(avblOffering.reference)
            this.availableOfferingsList.splice(index,1);
            this.removedAvlblOfferingsLength ++;
          }
        })
      });
    }
  }

  delAddedOffering (offering) {
    this.addedOfferingsList.forEach((item,index)=>{
      if(item.reference == offering.reference) {
        this.addedOfferingsList.splice(index,1);
        this.availableOfferingsList.unshift(offering);
        this.removedAvlblOfferingsLength --;
        this.changeInAddedOfferings = true;
      }
    });
  }

  prepareTemplateData () {
    const templateData = { 
      "name": `${this.templateForm.getRawValue().name}`,
       "description": `${this.templateForm.getRawValue().description}`, 
       "fromDate": new Date().toJSON(), 
       "offerings":this.getTheAddedOfferingIds()
    }
    return templateData;
  }

  getTheAddedOfferingIds () {
    let idValues = []
    if(this.addedOfferingsList.length){
      this.addedOfferingsList.forEach((item)=>{
        idValues.push(item.reference)
      })
    }
    return idValues;
  }

  viewOfferingDetails(reference){
    console.log(reference);
    this.openViewOfferingModel(reference)
  }
  openViewOfferingModel(reference) {
    let dialogRef = this.dialog.open(ViewProdOfferingComponent, {
      disableClose: false,
      height:'auto',
      width:'100%',
      panelClass: ['dialogComponentContainer'],
      data:{referenceId:reference}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  cancel () {
    this.cancelEvent.emit();
  }

  saveDraft () {
    this.saveDraftEvent.emit(this.prepareTemplateData());
  }

  deleteTemplate () {
    this.delTempEvent.emit(true);
  }

  isbtnDisabled(type) {
    let flag = true;
    if(type == 'cancel' || type == 'save'){
      if(this.templateForm.controls.name.value){
        flag = false;
      }
    }else{
      if(this.templateForm.controls.name.value && this.addedOfferingsList.length){
        flag = false;
      }
    }

    return flag;
   
  }
  publish () {
    this.publishEvent.emit(this.prepareTemplateData());
  }

  searchAddedOfferings () {
    this.addedOfferingsSearchCount = 0;
    if(this.addedOfferingsList && this.addedOfferingsList.length && this.searchText.length > 2){
      this.addedOfferingsList.forEach((element)=>{
        if(element.displayName && element.displayName.toLowerCase().includes(this.searchText.toLowerCase())){
          this.addedOfferingsSearchCount++;
        }
      })
    }
  }

  /**to open Filter modal */
  openFilterModal() {
    let data = {};
    if(this.filterCount > 0) {
      const filterData = {
        'categories' : this.filterPayload.categories,
        'applicationNameValues' : this.filterPayload.applicationNames,
        'productNameValues' : this.filterPayload.productNames,
        'createdByValues' : this.filterPayload.createdBy
      }
      data['filterData'] = filterData;
      data['filteredCount'] = this.filterCount;
    }
    let dialogRef = this.dialog.open(FilterModalComponent, {
      width: '50%',
      height: '95%',
      data: data
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data) {
        if (data.count) {
          this.searchOptions.skip =1;
        }
        this.filterCount = data.count;
        this.filterPayload.categories = data.categories;
        this.filterPayload.applicationNames = data.applicationNameValues;
        this.filterPayload.productNames = data.productNameValues;
        this.filterPayload.createdBy = data.createdByValues;
        // this.isFilterCleared = data.isFilterCleared;
        this.getAllOfferings(true);
      }
    });
  }
  
  checkCartusInsured(i) {
    let isCartusInsured = false;
    this.availableOfferingsList[i].scopingData && this.availableOfferingsList[i].scopingData.forEach(ele => {
      ele.items.forEach(item => {
        if (item.fact == 'clientIsCartusInsured' && item.value == true) {
          isCartusInsured = true;
        }
      });
      return isCartusInsured
    });
    return isCartusInsured;
  }
}
