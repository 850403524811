import { Component, OnInit,Input,Output,EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { ProdTemplatesService } from '../../services/product-templates.service';


@Component({
  selector: 'app-view-prod-template',
  templateUrl: './view-prod-template.component.html',
  styleUrls: ['./view-prod-template.component.scss'],
})
export class ViewProdTemplateComponent  implements OnInit,OnDestroy {

  templateId: any;
  templateName:any;
  templateDescription:any;
  showSearchInput = false;
  categoryCodes:any ={};

  addedOfferingsList = [];

  searchOptions = {
    expandOfferings:true,
    expandParties:true
  }

  searchText:any;
  tempStatus:any;
  templateInfo: any;
  searchCount = 0 ;
  showSearchCloseIcon = false;
  sub: any;
    constructor(public route: ActivatedRoute,
        public router: Router,
        public prodTemplatesService: ProdTemplatesService,
        private _Activatedroute:ActivatedRoute,
        public dialog: MatDialog){
          
    }

    ngOnInit(){ 
      console.log("ViewTemplateComponent loads");
      console.log(sessionStorage.getItem("tempStatus"));
      this.tempStatus  = sessionStorage.getItem("tempStatus");
      sessionStorage.removeItem('isDuplicate');
      // this.templateId = history.state ? history.state.id : undefined;
      if(sessionStorage.getItem('colorCodes')){
        this.categoryCodes = JSON.parse(sessionStorage.getItem('colorCodes'));
      }
      this.sub = this._Activatedroute.paramMap.subscribe(params => { 
        console.log(params);
        this.templateId = params.get('id'); 
        this.getTemplateInfo();
      });
      
    }

    getTemplateInfo () {
      if(this.templateId){
        this.prodTemplatesService.showSpinner();
        this.prodTemplatesService.getTemplateById(this.prepareSearchCriteria()).subscribe((response)=>{
          console.log(response);
          if(response && response[0]){
            sessionStorage.setItem('templateInfo',JSON.stringify(response[0]));
            this.templateName = response[0]['name'];
            this.templateDescription = response[0]['description'];
            this.addedOfferingsList = response[0]['offerings']
          }
          this.prodTemplatesService.hideSpinner();
        },(error)=>{
          this.prodTemplatesService.hideSpinner();
        });
      }else if(sessionStorage.getItem('templateInfo')){
        this.templateInfo = JSON.parse(sessionStorage.getItem('templateInfo'));
        this.templateName = this.templateInfo['name'];
        this.templateDescription = this.templateInfo['description'];
        this.addedOfferingsList = this.templateInfo['offerings']
      }
    }

    dispalyCategory (offering){
      let category = {
        displayName:null,
        colorCode:null,
        count:0,
        list:[]
      }
      if(offering.category && Array.isArray(offering.category)){
        category.list = offering.category
        category.displayName = offering.category[0];
        category.count =  category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(offering.category && typeof offering.category == 'string'){
        category.list = offering.category.split(',');
        category.displayName = category.list[0];
        category.count = category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(!offering.category && offering.productSpecificationReference){
        if(offering.productSpecificationReference[0] && offering.productSpecificationReference[0].category && Array.isArray(offering.productSpecificationReference[0].category)){
          category.list = offering.productSpecificationReference[0].category;
          category.displayName = category.list[0];
          category.count = category.list.length;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        }else if(offering.productSpecificationReference[0] && offering.productSpecificationReference[0].category && typeof offering.productSpecificationReference[0].category == 'string'){
          category.list = offering.productSpecificationReference[0].category.split(',');
          category.displayName = category.list[0];
          category.count = category.list.length;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        }else if(offering.productSpecificationReference.category && typeof offering.productSpecificationReference.category == 'string'){
          category.displayName = offering.productSpecificationReference.category;
          category.count = 1;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        }else if(offering.productSpecificationReference.category && Array.isArray(offering.productSpecificationReference.category)){
          category.displayName = offering.productSpecificationReference.category[0];
          category.count = offering.productSpecificationReference.category.length;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        }
      }
      return category;
    }

    getCreatedBy(createdByArray){
      let value = null;
      if(createdByArray && createdByArray[0] && createdByArray[0].currentName
         && createdByArray[0].currentName.names && createdByArray[0].currentName.names.length) {
          value = createdByArray[0].currentName.names.toString().replace(',',' ');
      }

      return value;
    }

    editTemplate(){
      this.router.navigate(['/edit-template',this.templateId ? this.templateId : this.templateInfo['_id']]);
    }

    duplicateTheTemp() {
      sessionStorage.setItem('isDuplicate','true');
      this.router.navigate(['/create-template']);
    
    }

    deleteTemplate(){
      if(this.tempStatus == 'Active'){
        let dialogRef =this.dialog.open(WarningModelComponent,{
          data:{
            title:"Delete Confirmation",
            content:"Are you sure you want to delete this Template? This template will be moved to ‘Archived Template’ .",
            actions:[
              {name:'Cancel',type:'close'},
              {name:'Delete',type:'delete'},
            ]
          },
          width:'32.74em'
        });
  
        dialogRef.afterClosed().subscribe((action) =>{
          if(action == 'delete'){
           this.changeActiveTempStatus();
          }
        })
      }else{
        let dialogRef =this.dialog.open(WarningModelComponent,{
          data:{
            title:"Permanent Delete Confirmation",
            content:"Are you sure you want to delete this Template permanently? You won’t be able to restore it.",
            actions:[
              {name:'Cancel',type:'close'},
              {name:'Delete',type:'delete'},
            ]
          },
          width:'32.74em'
        });
  
        dialogRef.afterClosed().subscribe((action) =>{
          if(action == 'delete'){
            let id = this.templateId ? this.templateId : this.templateInfo['_id'];
            this.prodTemplatesService.showSpinner();
           this.prodTemplatesService.deleteTemplateById(id).subscribe((response)=>{
            this.prodTemplatesService.hideSpinner();
            this.prodTemplatesService.onChangeInTemplates();
            this.goBack();
           },(error)=>{
            this.prodTemplatesService.hideSpinner();
           })
          }
        })
      }
      
    }

    changeActiveTempStatus () {
      this.prodTemplatesService.showSpinner();
        this.prodTemplatesService.moveTemplateToArchive(this.templateId).subscribe((response)=>{
          console.log(response);
          this.prodTemplatesService.hideSpinner();
          if(response){
            this.prodTemplatesService.onChangeInTemplates();
            this.goBack();
          }
        },(error)=>{
          this.prodTemplatesService.hideSpinner();
        });
    }

    restoreArchivedTempToDraft () {
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Restore Confirmation",
          type:'restore',
          content:"Are you sure you want to restore this Template? This template will be moved to ‘Draft Template’ .",
          actions:[
            {name:'Close',type:'close'},
            {name:'Restore',type:'restore'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
       
        if(action == 'restore'){
          this.prodTemplatesService.showSpinner();
          let id= this.templateId ? this.templateId : this.templateInfo['_id'];
          let tempData = {
            "fromDate": new Date(new Date().setFullYear(new Date().getFullYear()+50)).toJSON(),
             "toDate": new Date(new Date().setFullYear(new Date().getFullYear()+80)).toJSON()
          }
          this.prodTemplatesService.moveTemplateToDraft(id,tempData).subscribe((response)=>{
            this.prodTemplatesService.hideSpinner();
            this.prodTemplatesService.onChangeInTemplates();
            this.goBack();
          },(error)=>{
            this.prodTemplatesService.hideSpinner();
          });
        }
      })
    }

    goBack () {
      this.router.navigate(['/prod-menu-list/product-templates']);
    }

    prepareSearchCriteria () {
      return `/${this.templateId}?${Object.keys(this.searchOptions).map(key => `${key}=${this.searchOptions[key]}`).join("&")}`
    }

    searchOfferings () {
      this.searchCount = 0;
      if(this.addedOfferingsList && this.addedOfferingsList.length && this.searchText && this.searchText.length > 2){
        this.addedOfferingsList.forEach((element)=>{
          if(element.displayName && element.displayName.toLowerCase().includes(this.searchText.toLowerCase())){
            this.searchCount++;
          }
        })
      }
    }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}