import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProdOfferingsService } from '../../services/product-offerings.service';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AddTemplateModelComponent } from '../common/add-template-model/add-template-model.component';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { IconUploadModalComponent } from '../icon-upload-modal/icon-upload-modal.component';
import { ProdTemplatesService } from '../../services/product-templates.service';
import { ToastrService, GlobalConfig, ToastContainerDirective, } from 'ngx-toastr';


@Component({
  selector: 'app-base-prod-offering',
  templateUrl: './base-prod-offering.component.html',
  styleUrls: ['./base-prod-offering.component.scss']
})
export class BaseProdOfferingComponent implements OnInit, AfterViewInit, OnDestroy {
  options: GlobalConfig;
  searchValue: string;
  offeringAction = 'edit';
  categoryCodes: any;
  offeringStatus: string;
  offeringReferenceId: any;
  offeringInfo: any;
  offeringName: any;
  offeringDescription: any;
  restrictions: any;
  isSingleSelct: boolean;
  points: any;
  associatedTemplates: any[];
  offeringCategories: any[]
  productsList: any[] = [];
  offeringIcon: any[];
  icons: any;
  iconObj: any;
  offeringCategory: any;
  productName = "Home Sale Reiimbursment";
  imgSrc: string;
  iconSrc: string;
  selectedImgSrc: string;
  existingIcon: string = "../../assets/images/offering_sample_img.png";
  iconSrcName: string = "Offering Image Sample One";
  categoryInfo = {
    displayName: null,
    colorCode: '#00EBB3',
    count: 0,
    list: []
  }
  hiddenRestrictions = ['client']
  dispalyDelIcon: boolean = true;
  categoryList: any;
  offeringTypeList = [{
    displayValue: 'Multiplicity',
    value: 'Multiplicity'
  },
  {
    displayValue: 'Single',
    value: 'Single'
  }, {
    displayValue: 'Incremental',
    value: 'Incremental'
  },
  {
    displayValue: 'Hybrid Core/Flex',
    value: 'Hybrid'
  },
  {
    displayValue: 'Variable',
    value: 'Variable'
  }
  ];
  incrementTypes = [{
    displayValue: 'Timeframe',
    value: 'Timespan'
  },
  {
    displayValue: 'Value',
    value: 'Amount'
  }
  ];
  hybridType = [{
    displayValue: 'Timeframe',
    value: 'Timespan'
  }]
  timeFrameTypes = [{
    displayValue: 'Hours',
    value: 'Hours'
  },
  {
    displayValue: 'Days',
    value: 'Days'
  },
  {
    displayValue: 'Weeks',
    value: 'Weeks'
  },
  {
    displayValue: 'Months',
    value: 'Months'
  }
  ];
  hybridTimeframeType = [{
    displayValue: 'Days',
    value: 'Days'
  }, {
    displayValue: 'Months',
    value: 'Months'
  }];
  OfferingRulesList = [{
    displayValue: 'Enable Linked Offering',
    value: 'Enable Linked Offering'
  },
  {
    displayValue: 'Disable Linked Offering',
    value: 'Disable Linked Offering'
  },
  {
    displayValue: 'Select Linked Offering',
    value: 'Select Linked Offering'
  }
  ];
  matSelectFocused: any = {};
  isIncremental: boolean = true;
  isOpen: boolean = false;
  offeringCategoriesValue: any
  selectedRule: any;
  scopingCriteriaTypes = [];
  hiddenCriteriaTypes = ['Client'];
  offeringsData: any[] = [];
  searchOptions = {
    skip: 1,
    limit: 10,
    status: 'Active',
    searchText: '',
    onlyShowMine: false,
    sortDir: 'ASC'
  };
  timeOut: any;
  offeringType: string;
  categoryValueFrom = null;
  disableAddResBtn: boolean;
  disableSaveAndPublish: boolean;
  validOfferingDetails: boolean;
  invalidProduct: boolean;
  disableEditOffering: boolean;
  isMultipleCategoryAllwd: boolean = false; //Added support for multiple categories. to enable this in UI set it to 'true'.
  validTypeOfCategoryValue = 'string'; //Valid value is either 'string' or 'list'. This is applicable only when multiple categories are allowed.
  uniqueValues: any;
  newlegacy: any;
  myValues: any;
  productList = [];
  filteredProductOptions: Observable<any>;
  domesticProductList = [];
  internationalProductName: string;
  domesticProduct: string;
  domesticSubproduct: string;
  selectedClientId: string;
  InternationalsubProduct: Array<any>;
  domesticsubProduct: Array<any>;
  internationalFilterData: any;
  domesticFilterData: any;
  offeringInfoForm: FormGroup;
  offeringDetail: any;
  prodSubDetails?: {
    intProdName?: string,
    intSubProdName?: string,
    usProdName?: string,
    usSubProdName?: string
  }
  sub: any;
  showIP = false;
  showIS = false;
  showDP = false;
  showDS = false;
  checkIsBB = [];


  constructor(
    public prodOfferingsService: ProdOfferingsService,
    public prodTemplatesService: ProdTemplatesService,
    public router: Router,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private _Activatedroute: ActivatedRoute,
    public toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit(): void {

    console.log("ViewofferingComponent loads selected");
    if (sessionStorage.getItem('colorCodes')) {
      this.categoryCodes = JSON.parse(sessionStorage.getItem('colorCodes'));
    }
    if (sessionStorage.getItem("OfferingStatus")) {
      this.offeringStatus = sessionStorage.getItem("OfferingStatus");
    }
    let offeringHistoryData = history.state ? history.state.data : undefined;
    if (sessionStorage.getItem('colorCodes')) {
      this.categoryList = JSON.parse(sessionStorage.getItem('colorCodes'));
    }
    if (sessionStorage.getItem('offeringAction')) {
      this.offeringAction = sessionStorage.getItem('offeringAction');
    }
    this.sub = this._Activatedroute.paramMap.subscribe(params => {
      console.log(params);
      this.offeringReferenceId = params.get('id');
      this.getOfferingInfo(offeringHistoryData);
      if (this.offeringAction == 'create' || this.offeringAction == 'duplicate') {
        this.dispalyDelIcon = false;
        this.offeringStatus = null;
      }
      if (this.offeringAction == 'duplicate' && offeringHistoryData) {
        sessionStorage.setItem('offeringInfo', JSON.stringify(offeringHistoryData));
      }
    });
    this.getProductSubProdList();

    if (history?.state?.data && history?.state?.data["offeringDetail"]["icons"]) {
      this.iconSrc = history.state.data["offeringDetail"]["icons"].iconName;
      this.iconSrcName = history.state.data["offeringDetail"]["icons"].iconDisplayName;

    } else if (history?.state?.selectedIcon && history?.state?.selectedIcon[0]["iconDetails"]) {
      this.iconSrc = history.state.selectedIcon[0]["iconDetails"].iconName;
      this.iconSrcName = history.state.selectedIcon[0]["iconDetails"].iconDisplayName;
    } else {
      this.iconSrc = this.existingIcon;
      this.iconSrcName = this.iconSrcName;
    }
    this.prodOfferingsService.bbRole.next(['']);
  }

  getProductSubProdList() {
    this.prodOfferingsService.getNewLegacy().subscribe(res => {
      this.myValues = res[0];
      this.newlegacy = this.myValues.values;
      const k = 'productName';
      this.uniqueValues = [...new Map(this.newlegacy.map(item =>
        [item[k], item])).values()];
      let productName = "";
      let subProduct: any[];
      let productList = { "productName": productName, "subProductName": subProduct }
      this.uniqueValues.forEach(element => {
        this.productList.push(this.getSubProduct(this.newlegacy, element.productName));
        this.domesticProductList.push(this.getSubProduct(this.newlegacy, element.productName));
      });
      this.internationalFilterData = [...this.productList];
      this.domesticFilterData = [...this.domesticProductList];
    })
  }

  changeInternationalProduct(productName: any) {
    this.InternationalsubProduct = this.productList.find(con => con.productName == productName).subProductName;
    this.offeringInfoForm.get(['prodSubDetails', 'intSubProdName']).setValue("");
  }


  changeDomesticProduct(productName: any) {
    this.domesticsubProduct = this.domesticProductList.find(con => con.productName == productName).subProductName;
    this.offeringInfoForm.get(['prodSubDetails', 'usSubProdName']).setValue("");
  }


  getSubProduct(data, productName) {
    if (productName != "") {
      let subProduct = [];
      let products = data.filter(i => i.productName == productName)
      products.forEach(element => {
        subProduct.push(element.subProductName ? element.subProductName : "");
      });
      return { "productName": productName, "subProductName": subProduct }
    }
  }
  /**
    * Checks for Value or will return 'empty'
    * @param value any
    */
  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  generateProductForm(data): FormGroup {
    const check = this.isNullCheck;
    let prodSubDetails: FormGroup = this.formBuilder.group({
      intProdName: [data.prodSubDetails ? check(data.prodSubDetails, 'intProdName') : ''],
      intSubProdName: [data.prodSubDetails ? check(data.prodSubDetails, 'intSubProdName') : ''],
      usProdName: [data.prodSubDetails ? check(data.prodSubDetails, 'usProdName') : ''],
      usSubProdName: [data.prodSubDetails ? check(data.prodSubDetails, 'usSubProdName') : ''],
    });
    this.checkIsBB[0] && this.checkIsBB[0] == 'BenefitsBuilder' ? prodSubDetails.setValidators(this.checkIsValid()) : prodSubDetails.clearValidators();
    return prodSubDetails;
  }

  internationalUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.internationalFilterData.filter((e) => {
      return e.productName.toLowerCase().indexOf(val) !== -1 || !val
    })
    this.productList = temp
  }

  domesticUpdateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.domesticFilterData.filter((e) => {
      return e.productName.toLowerCase().indexOf(val) !== -1 || !val
    })
    this.domesticProductList = temp
  }

  onClearIntProduct() {
    this.offeringInfoForm.get(['prodSubDetails', 'intProdName']).setValue("");
  }

  onClearIntSubProduct() {
    this.offeringInfoForm.get(['prodSubDetails', 'intSubProdName']).setValue("");
  }

  onClearDomProduct() {
    this.offeringInfoForm.get(['prodSubDetails', 'usProdName']).setValue("");
  }

  onClearDomSubProduct() {
    this.offeringInfoForm.get(['prodSubDetails', 'usSubProdName']).setValue("");
  }

  checkIsValid(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls['intProdName'];
      const control2 = group.controls['usProdName'];
      if (control1.value != '' || control2.value != '') {
        control2.setErrors(null);
      } else {
        control2.setErrors({ nullValue: true });
      }
      return;
    };
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  goBack() {
    this.router.navigate(['/prod-menu-list/product-offerings']);
  }

  selected(event: MatAutocompleteSelectedEvent, index, id): void {
    // this.offeringInfo.rules[index].offerings.push(event.option.value);
    (this.offeringInfoForm.get('rules').get('' + index).get('offerings') as FormArray).push(this.addRuleOfferingValue(event.option.value));
    document.getElementById(id)['value'] = null;
  }

  isOfferingSelected(offering) {
    let disabled = false;
    let rulesVal = this.offeringInfoForm.get('rules').value;
    if (rulesVal.length && this.offeringsData.length) {
      rulesVal.forEach(element => {
        if (element.offerings && element.offerings.length) {
          element.offerings.forEach(element => {
            if (offering.reference == element.reference) {
              disabled = true;
            }
          });
        }
      });
    }
    return disabled;
  }

  getAvailableOfferings() {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getAllOfferings(this.prepareOfferingSearchCriteria()).subscribe((response) => {
      // console.log(response);
      this.prodOfferingsService.hideSpinner();
      if (response && response.body.length) {
        this.offeringsData = response.body
      }
    }, (error) => {
      this.prodOfferingsService.hideSpinner();
    });
  }

  getScopingCriteriaTypes() {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getvalueLists('ScopingCriteriaTypes').subscribe((response) => {
      this.prodOfferingsService.hideSpinner();
      if (response && response[0]) {
        this.scopingCriteriaTypes = response[0]['values'];
        this.changeInRestriction();
        // console.log(response);
      }
    }, (error) => {
      this.prodOfferingsService.hideSpinner();
    })
  }

  removeSelectedOffering(offering, ruleIndex, OfferingIndex) {
    (this.offeringInfoForm.get('rules').get('' + ruleIndex).get('offerings') as FormArray).removeAt(OfferingIndex);
  }

  searchOfferings(event) {
    clearTimeout(this.timeOut);
    if (!this.disableEditOffering && event.currentTarget.value && event.currentTarget.value.length > 2) {
      this.searchOptions.searchText = event.currentTarget.value;
      this.timeOut = setTimeout(() => {
        this.getAvailableOfferings();
      }, 1000);
    }
  }

  removeRestriction(event) {
    this.prodOfferingsService.bbRole.next(['']);
    let indexVal = -1;
    let restrictionsVal = this.offeringInfoForm.get('restrictions').value;
    restrictionsVal.forEach((element, index) => {
      if (element.type == event) {
        indexVal = index;
      }
    });
    if (indexVal > -1) {
      (this.offeringInfoForm.get('restrictions') as FormArray).removeAt(indexVal);
      this.changeInRestriction();
    }
  }

  addScopingCriteria(criteiria) {
    this.isOpen = !this.isOpen;
    switch (criteiria.uiType) {
      case 'application':
        criteiria.values = [null];
        break;
      case 'location':
        criteiria.values = [
          {
            "value": null,
            "country": null,
            "cityStPr": []
          }
        ]
        break;
      case 'complexRule':
        criteiria.values = [{
          "_id": null,
          "displayName": null
        }]
        break;
      case 'dropdown':
        criteiria.values = [
          null
        ]
        break;
      case 'operator':
        criteiria.values = [
          {
            "operator": null,
            "value": null
          }
        ]
        break;
      case 'toggle':
        criteiria.values = [false]
        break;
    }
    (this.offeringInfoForm.get('restrictions') as FormArray).insert(0, this.addNewRestrictionForm(criteiria));
    this.changeInRestriction();
  }

  checkForBBRole() {
    this.checkIsBB = this.prodOfferingsService.bbRole.getValue();
    if (this.checkIsBB[0] && this.checkIsBB[0] == 'BenefitsBuilder') {
      this.offeringInfoForm.get('prodSubDetails').setValidators(this.checkIsValid());
      return true;
    } else {
      this.offeringInfoForm.get('prodSubDetails').clearValidators();
      return false;
    }
  }

  changeInRestriction() {
    this.disableAddResBtn = false;
    let totalAddedRestriction = 0;
    let restrictionsVal = this.offeringInfoForm.get('restrictions').value;
    if (this.scopingCriteriaTypes && this.scopingCriteriaTypes.length) {
      this.scopingCriteriaTypes.forEach((item: any) => {
        item.disabled = false;
        item.visible = true;
        if (this.hiddenCriteriaTypes.indexOf(item.type) > -1) {
          totalAddedRestriction++;
          item.visible = false;
        }
        if (restrictionsVal.length) {
          restrictionsVal.forEach((element) => {
            if (element.type == item.type) {
              item.disabled = true;
              totalAddedRestriction++;
            }
          });
        }
      });
    }
    if (totalAddedRestriction == this.scopingCriteriaTypes.length) {
      this.disableAddResBtn = true;
    }
  }

  getOfferingInfo(historyData?) {
    if (this.offeringReferenceId && !historyData) {
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getOfferingByReference(this.offeringReferenceId).subscribe((response) => {
        console.log(response);
        this.prodOfferingsService.hideSpinner();
        if (response && response[0]) {
          this.prepareOfferingInfo(response[0]);
        }
      }, (error) => {
        this.prodOfferingsService.hideSpinner();
      });
    } else if (this.offeringAction == 'duplicate' && !historyData) {
      this.prepareOfferingInfo(JSON.parse(sessionStorage.getItem('offeringInfo')));
    } else {
      this.prepareOfferingInfo(historyData ? historyData : this.getEmptyOffering());
    }
  }

  prepareOfferingInfo(response) {
    this.offeringInfo = Object.assign({}, response);
    this.offeringName = this.offeringInfo['offeringDetail']['displayName'];
    this.offeringType = this.offeringInfo['offeringDetail']['type']
    this.offeringDescription = this.offeringInfo['offeringDetail'] && this.offeringInfo['offeringDetail']['description'] ? this.offeringInfo['offeringDetail']['description'] : this.offeringInfo['offeringDetail'] && this.offeringInfo['offeringDetail']['productReference'] ? this.offeringInfo['offeringDetail']['productReference']['description'] : '';
    this.restrictions = this.filterRestrictions(this.offeringInfo['restrictions']);
    this.generateProductForm(this.offeringInfo['offeringDetail']);
    this.restrictions = Array.isArray(this.restrictions) ? this.restrictions.sort((a, b) => { return a['order'] - b['order'] }) : this.restrictions;
    this.isSingleSelct = this.getOfferingType();
    this.points = this.getPoints(this.offeringInfo);
    this.isIncremental = this.offeringInfo && typeof this.offeringInfo['incremental'] == 'object' && Object.keys(this.offeringInfo['incremental']).length ? true : false;
    this.dispalyCategory(this.offeringInfo['offeringDetail']);
    this.offeringStatus = this.offeringInfo['offeringDetail'] && this.offeringInfo['offeringDetail']['status'] ? this.offeringInfo['offeringDetail']['status'] : this.offeringStatus;
    this.offeringCategory = this.isMultipleCategoryAllwd ? this.categoryInfo.list : this.categoryInfo.displayName;
    this.disableEditOffering = this.offeringInfo['offeringDetail'] && this.offeringInfo['offeringDetail']['isCashOutOffering'] ? this.offeringInfo['offeringDetail']['isCashOutOffering'] : false;
    if (this.offeringAction == 'duplicate') {
      this.offeringName = 'Copy of ' + this.offeringName;
      this.offeringInfo.offeringDetail.displayName = this.offeringName;
      this.offeringStatus = null;
      delete this.offeringInfo.offeringDetail.name;
    }
    if (!this.offeringInfo['incremental'] && !this.isIncremental) {
      this.offeringInfo['incremental'] = {
        "type": "Timespan",
        "timeframe": "Hours",
        "incrementValue": 0,
        "maxIncrementValue": 1
      }
    }
    if (this.offeringReferenceId && this.offeringStatus == 'Active') {
      this.getTemplatesInfo(this.offeringReferenceId);
    }
    this.getScopingCriteriaTypes();
    this.offeringInfoForm = this.getOfferingForm();
    if (this.disableEditOffering) {
      this.offeringInfoForm.disable();
    }
    if (this.offeringAction == 'edit') {
      this.offeringInfoForm.get('offeringDetail').get('productReference').disable();
      // if(this.isSingleSelct){
      //   this.offeringInfoForm.get('offeringDetail').get('type').disable();
      // }
    }
    if(this.offeringAction == 'edit' && !this.offeringInfo.offeringDetail.productMultiplicityReference) {
      this.offeringTypeList.splice(0,1);
    }
    // this.offeringInfoForm.valueChanges.subscribe((newValue)=>{
    //   console.log(newValue);
    // })
    this.changeInRestriction();
  }

  getPoints(offeringInfo) {
    let value: any = "Core";
    if (offeringInfo && offeringInfo.restrictions && offeringInfo.restrictions.length) {
      let restrictions = offeringInfo.restrictions;
      restrictions.forEach(item => {
        if (item.type && item.type == 'Application') {
          value = item.points && parseInt(item.points) && parseInt(item.points) > 0 ? parseInt(item.points) : "Core";
        }
      });
    }

    return value;
  }

  filterRestrictions(restrictions) {
    let finalValue = []
    if (restrictions) {
      restrictions.forEach(element => {
        if (this.hiddenRestrictions.indexOf(element.uiType) == -1) {
          finalValue.push(element);
        }
      });
    }
    return finalValue;
  }

  getOfferingType() {
    let flag = this.offeringInfo && this.offeringInfo['offeringDetail']['type'] && this.offeringInfo['offeringDetail']['type'] == 'Single' ? true : false;
    if (this.offeringInfo['offeringDetail'] && this.offeringInfo['offeringDetail']['productMultiplicityReference'] && Object.keys(this.offeringInfo['offeringDetail']['productMultiplicityReference']).length) {
      flag = false;
    }

    return flag;
  }

  dispalyCategory(offering) {
    this.categoryValueFrom = null;
    if (offering.category && Array.isArray(offering.category)) {
      this.categoryInfo.list = offering.category;
      this.categoryInfo.displayName = offering.category[0];
      this.categoryInfo.count = offering.category.length;
      this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')].color
      this.categoryValueFrom = 'offeringLevel';
    } else if (offering.category && typeof offering.category == 'string') {
      this.categoryInfo.list = offering.category.split(',');
      this.categoryInfo.displayName = this.categoryInfo.list[0];
      this.categoryInfo.count = this.categoryInfo.list.length;
      this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')].color
      this.categoryValueFrom = 'offeringLevel';
    } else if (!offering.category && offering.productReference) {
      if (offering.productReference.category && Array.isArray(offering.productReference.category)) {
        this.categoryInfo.list = offering.productReference.category
        this.categoryInfo.displayName = this.categoryInfo.list[0];
        this.categoryInfo.count = this.categoryInfo.list.length;
        this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')].color
        this.categoryValueFrom = 'productLevel';
      } else if (offering.productReference.category && typeof offering.productReference.category == 'string') {
        this.categoryInfo.list = offering.productReference.category.split(',');
        this.categoryInfo.displayName = this.categoryInfo.list[0];
        this.categoryInfo.count = this.categoryInfo.list.length;
        this.categoryInfo.colorCode = this.categoryCodes && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')] && this.categoryCodes[this.categoryInfo.displayName.replaceAll(' ', '')].color
        this.categoryValueFrom = 'productLevel';
      }
    }

  }

  getTemplatesInfo(referenceVal) {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getTemplatesByReference(referenceVal).subscribe((response) => {
      this.prodOfferingsService.hideSpinner();
      if (response) {
        this.associatedTemplates = response;
      }

    }, (error) => {
      this.prodOfferingsService.hideSpinner();
    })
  }

  preventLineBreak(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  matFocusedEvent(event, key) {
    this.matSelectFocused[key] = event;
  }

  removeRule(index) {
    (this.offeringInfoForm.get('rules') as FormArray).removeAt(index);
  }

  addNewRule() {
    let ruleDataConfig = {
      rule: '',
      offerings: []
    };
    (this.offeringInfoForm.get('rules') as FormArray).push(this.addRuleControls(ruleDataConfig));
  }

  removeTask(index) {
    (this.offeringInfoForm.get('tasks') as FormArray).removeAt(index);
  }

  addNewTask() {
    let task = '';
    (this.offeringInfoForm.get('tasks') as FormArray).push(new FormControl(task, Validators.required));
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.offeringInfoForm.get('tasks')['controls'], event.previousIndex, event.currentIndex);
    moveItemInArray(this.offeringInfoForm.get('tasks').value, event.previousIndex, event.currentIndex);
  }

  clearData(type) {
    if (type == 'tasks') {
      (this.offeringInfoForm.get('tasks') as FormArray).clear();
    }
    if (type == 'rules') {
      (this.offeringInfoForm.get('rules') as FormArray).clear();
    }
  }

  getCategoryValues() {
    let valueList = this.categoryList ? Object.values(this.categoryList) : [];
    return valueList.sort((a, b) => {
      return a['order'] - b['order'];
    })
  }

  isSelcetedRule(rule, indexVal) {
    let flag = false;
    let rulesVal = this.offeringInfoForm.get('rules').value;
    if (rulesVal && rulesVal.length) {
      rulesVal.forEach((element, index) => {
        if (element.rule == rule && indexVal != index) {
          flag = true;
        }
      });
    }

    return flag;
  }

  addToTemplate() {
    let templateData = this.associatedTemplates && this.associatedTemplates.length ? [...this.associatedTemplates] : [];
    let dialogRef = this.dialog.open(AddTemplateModelComponent, {
      data: {
        title: "Select Template",
        actions: [
          { name: 'Close', type: 'close' },
          { name: 'Add to Template', type: 'add' },
        ],
        templates: templateData
      },
      width: '832px',
      height: '877px',
      panelClass: 'add-template-modalbox'
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action.type == 'add') {
        this.associatedTemplates = action.data;
      }
    })
  }

  removeTemplate(template) {
    let templateFound = false;
    let indexVal;
    this.associatedTemplates.forEach((element, index) => {
      if (element._id == template._id) {
        templateFound = true;
        indexVal = index;
      }
    });
    if (templateFound) {
      this.associatedTemplates.splice(indexVal, 1);
    }
  }

  changeMinMaxValues(type, field) {
    if (type == "increment") {
      if (field == 'min' && this.offeringInfoForm.get('offeringDetail').get('multiMin').value < this.offeringInfoForm.get('offeringDetail').get('multiMax').value) {
        this.offeringInfoForm.get('offeringDetail').get('multiMin').setValue(this.offeringInfoForm.get('offeringDetail').get('multiMin').value + 1);
      }
      if (field == 'max') {
        this.offeringInfoForm.get('offeringDetail').get('multiMax').setValue(this.offeringInfoForm.get('offeringDetail').get('multiMax').value + 1);
      }
      if (field == 'increment') {
        this.offeringInfoForm.get('incremental').get('maxIncrementValue').setValue(this.offeringInfoForm.get('incremental').get('maxIncrementValue').value + 1);
      }
    }
    if (type == "decrement") {
      if (field == 'min' && this.offeringInfoForm.get('offeringDetail').get('multiMin').value > 0) {
        this.offeringInfoForm.get('offeringDetail').get('multiMin').setValue(this.offeringInfoForm.get('offeringDetail').get('multiMin').value - 1);
      }
      if (field == 'max' && this.offeringInfoForm.get('offeringDetail').get('multiMax').value > this.offeringInfoForm.get('offeringDetail').get('multiMin').value) {
        this.offeringInfoForm.get('offeringDetail').get('multiMax').setValue(this.offeringInfoForm.get('offeringDetail').get('multiMax').value - 1);
      }
      if (field == 'increment' && this.offeringInfoForm.get('incremental').get('maxIncrementValue').value > 1) {
        this.offeringInfoForm.get('incremental').get('maxIncrementValue').setValue(this.offeringInfoForm.get('incremental').get('maxIncrementValue').value - 1);
      }
    }
  }

  prepareOfferingSearchCriteria() {
    let params = this.searchOptions;
    return Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  }

  minMaxValidations(event, backSpace, min?, max?) {
    if (event.key == 'e' || event.key == 'E' || event.key==='.' || event.key == '-' || event.key == '+' || (backSpace && event.key == 'Backspace')) {
      event.preventDefault();
    }
    min = !min ? 0 : min;
    let value = parseInt(event.target.value + event.key);
    value = event.key == 'ArrowDown' ? value - 1 : event.key == 'ArrowUp' ? value + 1 : value;
    if (value < 0) {
      event.preventDefault();
    } else if (value > -1) {
      if (max && max < value) {
        event.preventDefault();
      }
      if (min >= value && min.toString().length <= value.toString().length) {
        event.preventDefault();
      }
      if(event.charCode >= 48 && event.charCode <= 57){
        event.preventDefault();
      }
    }
  }

  incrementTypesChange(value) {
    if (value == 'Amount') {
      this.offeringInfoForm.get('incremental').get('incrementValue').setValue(500);
      this.offeringInfoForm.get('incremental').get('maxIncrementValue').setValue(1);
    } else {
      this.offeringInfoForm.get('incremental').get('incrementValue').setValue(0);
      this.offeringInfoForm.get('incremental').get('maxIncrementValue').setValue(1);
    }
    // if (value == 'Amount' || value == 'Value') {
    //   (this.offeringInfoForm.get('incremental') as FormGroup).removeControl('timeframe');
    // } else {
    //   if (!this.offeringInfoForm.get('incremental').get('timeframe')) {
    //     (this.offeringInfoForm.get('incremental') as FormGroup).addControl('timeframe', new FormControl('Hours', Validators.required));
    //   } else {
    //     this.offeringInfoForm.get('incremental').get('timeframe').setValue('Hours');
    //   }
    // }
  }

  incrementValueChange() {
    if (!this.offeringInfoForm.get('incremental').get('maxIncrementValue').value || this.offeringInfoForm.get('incremental').get('maxIncrementValue').value < 1) {
      this.offeringInfoForm.get('incremental').get('maxIncrementValue').setValue(1);
    }
    if (!this.offeringInfoForm.get('incremental').get('incrementValue').value || this.offeringInfoForm.get('incremental').get('incrementValue').value < 0) {
      this.offeringInfoForm.get('incremental').get('incrementValue').setValue(0);
    }
    if(!this.offeringInfoForm.get('incremental').get('timeGuaranteed').value || this.offeringInfoForm.get('incremental').get('timeGuaranteed').value < 0){
      this.offeringInfoForm.get('incremental').get('timeGuaranteed').setValue(0);
    }
  }

  minMaxValueChange() {
    if (this.offeringInfoForm.get('offeringDetail').get('multiMax').value < this.offeringInfoForm.get('offeringDetail').get('multiMin').value) {
      this.offeringInfoForm.get('offeringDetail').get('multiMax').setValue(10);
    }
    if(this.offeringInfoForm.get('offeringDetail').get('multiMin').value == null){
      this.offeringInfoForm.get('offeringDetail').get('multiMin').setValue(1);
    }
  }

  productCategoryChange() {
    this.offeringInfo.offeringDetail.category = this.isMultipleCategoryAllwd ? this.validTypeOfCategoryValue == 'string' ? this.offeringCategory.join(',') : this.offeringCategory : this.offeringCategory;
  }

  offeringTypeChange() {
    if (this.offeringInfoForm.get('offeringDetail') && this.offeringInfoForm.get('offeringDetail').get('type').value == 'Hybrid' || this.offeringInfoForm.get('offeringDetail') && this.offeringInfoForm.get('offeringDetail').get('type').value == 'Incremental') {
      this.offeringInfoForm.get('incremental').patchValue({
        "type": "Timespan",
        "timeframe": "Hours",
        "incrementValue": 0,
        "maxIncrementValue": 0,
        "flexTotalValues": 0,
        "timeGuaranteed": 0
      });
    }
    if (this.offeringInfoForm.get('offeringDetail') && this.offeringInfoForm.get('offeringDetail').get('type').value == 'Hybrid') {
      this.offeringInfoForm.get('incremental').patchValue({
        "timeframe": "Days"
      });
    }
    if (this.offeringInfoForm.get('offeringDetail') && this.offeringInfoForm.get('offeringDetail').get('type').value == 'Single') {
      (this.offeringInfoForm.get('offeringDetail') as FormGroup).removeControl('multiMax');
      (this.offeringInfoForm.get('offeringDetail') as FormGroup).removeControl('multiMin');
    } else {
      if (!this.offeringInfoForm.get('offeringDetail').get('multiMax')) {
        (this.offeringInfoForm.get('offeringDetail') as FormGroup).addControl('multiMax', new FormControl(10, Validators.required));
      } else {
        this.offeringInfoForm.get('offeringDetail').get('multiMax').setValue(10);
      }
      if (!this.offeringInfoForm.get('offeringDetail').get('multiMin')) {
        (this.offeringInfoForm.get('offeringDetail') as FormGroup).addControl('multiMin', new FormControl(1, Validators.required));
      } else {
        this.offeringInfoForm.get('offeringDetail').get('multiMin').setValue(1);
      }
    }
  }

  incrementalChanges() {
    // if(this.offeringInfoForm.get('isIncremental').value){
    //reset incremental values
    let incremental = {
      "type": "Timespan",
      "timeframe": "Hours",
      "incrementValue": 0,
      "maxIncrementValue": 0,
      "flexTotalValues": 0,
      "timeGuaranteed": 0
    }
    if (!this.offeringInfoForm.get('incremental')) {
      this.offeringInfoForm.addControl('incremental', this.addIncrematalForm(incremental));
    } else {
      this.offeringInfoForm.get('incremental').enable();
    }
    this.offeringInfoForm.controls
    // }else{
    // this.offeringInfoForm.removeControl('incremental')
    // this.offeringInfoForm.get('incremental').disable();
    // }
  }

  productDisplayFn(product) {
    return product && product.displayName ? product.displayName : '';
  }

  getAllProducts(event) {
    clearTimeout(this.timeOut);
    if (event.currentTarget.value && event.currentTarget.value.length > 2) {
      let searchText = event.currentTarget.value;
      this.timeOut = setTimeout(() => {
        this.productsList = [];
        this.prodOfferingsService.showSpinner();
        this.prodOfferingsService.getAllProducts(searchText).subscribe((response) => {
          this.prodOfferingsService.hideSpinner();
          if (response) {
            this.productsList = response;
          }
        }, (error) => {
          this.productsList = [];
          this.prodOfferingsService.hideSpinner();
        });
      }, 1000);
    }
  }

  getProductDetails(event) {
    let productReference = event.option.value ? event.option.value.reference : null;
    if (productReference) {
      this.invalidProduct = false;
      this.isSingleSelct = true;
      this.offeringInfoForm.get('offeringDetail').get('type').setValue('Single');
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getProductSpecs(productReference).subscribe((response) => {
        this.prodOfferingsService.hideSpinner();
        if (response) {
          console.log(response);
          this.offeringInfoForm.get('offeringDetail').get('productReference').setValue(response['productReference']);
          this.offeringInfoForm.get('offeringDetail').get('productMultiplicityReference').setValue(response['productMultiplicityReference']);
          if (response['productMultiplicityReference'] && typeof response['productMultiplicityReference'] == 'object'
            && Object.keys(response['productMultiplicityReference']).length) {
              if(this.offeringTypeList[0].displayValue != 'Multiplicity'){
                this.offeringTypeList.splice(0,0,{displayValue: 'Multiplicity',value: 'Multiplicity'});
              }
            this.offeringInfoForm.get('offeringDetail').get('type').setValue('Multiplicity');
            this.offeringInfoForm.get('offeringDetail').get('type').enable();
            this.isSingleSelct = false;
          }else{
            this.offeringTypeList.splice(0,1);
          }

        } else {
          this.offeringInfoForm.get('offeringDetail').get('productReference').setValue('');
          this.offeringInfoForm.get('offeringDetail').get('productMultiplicityReference').setValue('');
          this.offeringInfoForm.get('offeringDetail').get('type').setValue('');
          this.invalidProduct = true;
        }
        // if(this.isSingleSelct){
        //   this.offeringInfoForm.get('offeringDetail').get('type').disable();
        // }
      }, (error) => {
        this.invalidProduct = true;
        this.offeringInfoForm.get('offeringDetail').get('productReference').setValue('');
        this.offeringInfoForm.get('offeringDetail').get('productMultiplicityReference').setValue('');
        this.offeringInfoForm.get('offeringDetail').get('type').setValue('');
      })
    }
  }
  isProductValid(event) {
    if (typeof this.offeringInfoForm.get('offeringDetail').get('productReference').value != 'object') {
      this.offeringInfoForm.get('offeringDetail').get('productReference').setValue('');
    }
  }

  cancel() {
    if (this.offeringAction != 'create') {
      let dialogRef = this.dialog.open(WarningModelComponent, {
        data: {
          title: "Discard Changes",
          content: "Are you sure you want to discard the changes on this Offering? You will lose all the changes that you have made.",
          actions: [
            { name: 'Cancel', type: 'close' },
            { name: 'Discard', type: 'discard' },
          ]
        },
        width: '32.74em'
      });

      dialogRef.afterClosed().subscribe((action) => {
        if (action == 'discard') {
          this.router.navigate(['/prod-menu-list/product-offerings']);
        }
      })
    } else {
      this.router.navigate(['/prod-menu-list/product-offerings']);
    }

  }

  save(action) {
    if (this.offeringInfoForm.valid && !this.disableEditOffering) {
      let offeringFormValue = this.offeringInfoForm.getRawValue();
      offeringFormValue.offeringDetail = Object.assign(this.offeringInfo.offeringDetail, offeringFormValue.offeringDetail);
      offeringFormValue.offeringDetail.category = this.isMultipleCategoryAllwd ? this.validTypeOfCategoryValue == 'string' ? this.offeringCategory.join(',') : this.offeringCategory : this.offeringCategory;
      offeringFormValue.offeringDetail.status = action;
      if (this.offeringInfoForm.controls.prodSubDetails.value) {
        offeringFormValue.offeringDetail.prodSubDetails = this.offeringInfoForm.controls.prodSubDetails.value;
        delete offeringFormValue.prodSubDetails;
      }
      if (this?.iconObj?.iconName) {
        this.offeringInfo.offeringDetail.icons = {
          iconName: this.iconObj.iconName.split('/').pop(),
          iconDisplayName: this.iconObj.iconDisplayName
        };
      }
      else if (history?.state?.data && history?.state?.data["offeringDetail"]["icons"]) {
        this.offeringInfo.offeringDetail.icons = {
          iconName: history.state.data["offeringDetail"]["icons"].iconName.split('/').pop(),
          iconDisplayName: history.state.data["offeringDetail"]["icons"].iconDisplayName
        };
      } else if (history?.state?.selectedIcon && history?.state?.selectedIcon[0]["iconDetails"]) {
        this.offeringInfo.offeringDetail.icons = {
          iconName: history.state.selectedIcon[0]["iconDetails"].iconName.split('/').pop(),
          iconDisplayName: history.state.selectedIcon[0]["iconDetails"].iconDisplayName
        }
      } else {
        this.offeringInfo.offeringDetail.icons = {
          iconName: "enabled_generic_benefit_icon.svg",
          iconDisplayName: "Default"
        };
      }

      this.prepareRestrictions(offeringFormValue.restrictions);
      if (!offeringFormValue.isIncremental) {
        delete offeringFormValue.incremental;
      }
      if (offeringFormValue.incremental && offeringFormValue.incremental.type != 'Timespan') {
        delete offeringFormValue.incremental.timeframe;
      }
      if (this.offeringAction == 'create' || this.offeringAction == 'duplicate') {
        delete offeringFormValue.offeringDetail._id;
        delete offeringFormValue.offeringDetail.reference;
        delete offeringFormValue.offeringDetail.fromDate;
      }
      if (!offeringFormValue.offeringDetail.name) {
        delete offeringFormValue.offeringDetail.name;
      }
      if (!offeringFormValue.offeringDetail.productMultiplicityReference) {
        delete offeringFormValue.offeringDetail.productMultiplicityReference;
      }
      if (this.offeringInfoForm.get('offeringDetail').get('type').value != 'Multiplicity') {
        delete offeringFormValue.offeringDetail.multiMax;
        delete offeringFormValue.offeringDetail.multiMin;
        delete offeringFormValue.offeringDetail.productMultiplicityReference;
        delete offeringFormValue.offeringDetail.singleSelectMultiplicity;
      }
      if (this.offeringInfoForm.get('offeringDetail').get('type').value == 'Incremental' || this.offeringInfoForm.get('offeringDetail').get('type').value == 'Hybrid') {
        offeringFormValue.incremental = {
          type: this.offeringInfoForm.get('incremental').get('type').value,
          timeframe: this.offeringInfoForm.get('incremental').get('timeframe').value,
          incrementValue: this.offeringInfoForm.get('incremental').get('incrementValue').value,
          maxIncrementValue: this.offeringInfoForm.get('incremental').get('maxIncrementValue').value,
          timeGuaranteed: this.offeringInfoForm.get('incremental').get('timeGuaranteed').value
        }
        if (this.offeringInfoForm.get('offeringDetail').get('type').value == 'Incremental') {
          delete offeringFormValue.incremental.timeGuaranteed;
          if(this.offeringInfoForm.get('incremental').get('type').value == 'Amount' ){
            delete offeringFormValue.incremental.timeframe;
          }
        }
      }
      if (this.offeringInfoForm.get('offeringDetail').get('type').value == 'Multiplicity' || this.offeringInfoForm.get('offeringDetail').get('type').value == 'Single') {
        delete offeringFormValue.incremental;
      }
      if (this.offeringInfoForm.get('offeringDetail').get('type').value == 'Variable') {
        offeringFormValue.restrictions.forEach((element) => {
          if (element.type === 'Application' && (element.values.length == 1) && element.values.includes('BenefitsBuilder', 0)) {
            delete element.points;
          }
        });
        if (offeringFormValue.incremental) {
          delete offeringFormValue.incremental;
        }
      }

      delete offeringFormValue.offeringDetail.createdAt;
      delete offeringFormValue.offeringDetail.createdBy;
      delete offeringFormValue.offeringDetail.updatedBy;
      delete offeringFormValue.isIncremental;

      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.saveOffering(offeringFormValue).subscribe((response) => {
        this.prodOfferingsService.hideSpinner();
        if (response) {
          this.prodOfferingsService.onChangeInOfferings();
          this.prodTemplatesService.onChangeInTemplates();
          if (this.offeringReferenceId && this.offeringStatus == "Active") {
            this.updateAssociatedTemplates();
          } else {
            this.router.navigate(['/prod-menu-list/product-offerings']);
          }

        }
      }, (error) => {
        this.prodOfferingsService.hideSpinner();
        const httpErr = error.toString().split(':')[2];
        const opt = JSON.parse(JSON.stringify(this.options));
        this.toastr.show(
          httpErr,
          'Error',
          opt
        );
      })

    } else if (this.disableEditOffering && this.offeringReferenceId) {
      this.updateAssociatedTemplates();
    }


  }

  prepareRestrictions(restrictions) {
    if (restrictions && restrictions.length) {
      restrictions.forEach(item => {
        delete item.disabled;
        delete item.visible;
      })
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  updateAssociatedTemplates() {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.updateTemplatesToOffering(this.offeringReferenceId, this.prepareTemplates()).subscribe((response) => {
      this.prodOfferingsService.hideSpinner();
      if (response) {
        this.goBack();
      }
    }, (error) => {
      const httpErr = error.toString().split(':')[2];
      this.prodOfferingsService.hideSpinner();
      const opt = JSON.parse(JSON.stringify(this.options));
      this.toastr.show(
        httpErr,
        'Error',
        opt
      );
    })
  }

  prepareTemplates() {
    let obj = {
      "draftTemplateIds": [],
      "existingTemplateIds": []
    }
    if (this.associatedTemplates && this.associatedTemplates.length) {
      this.associatedTemplates.forEach(item => {
        if (item.status == "Existing" || item.status == "Active") {
          obj.existingTemplateIds.push(item._id)
        }
        if (item.status == "Draft") {
          obj.draftTemplateIds.push(item._id)
        }
      })
    }
    return obj;
  }

  openDeleteModel() {
    let title = "Delete Confirmation";
    let content = "Are you sure you want to delete this Offering? This offering will be moved to ‘Archived Offering’ .";
    let title1 = "Permanent Delete Confirmation";
    let content2 = "Are you sure you want to delete this Offering permanently? You won’t be able to restore it.";

    let dialogRef = this.dialog.open(WarningModelComponent, {
      data: {
        title: this.offeringStatus == 'Active' ? title : title1,
        content: this.offeringStatus == 'Active' ? content : content2,
        actions: [
          { name: 'Cancel', type: 'close' },
          { name: 'Delete', type: 'delete' },
        ]
      },
      width: '32.74em'
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action == 'delete') {
        this.prodOfferingsService.showSpinner();
        let action = this.offeringStatus == 'Draft' ? this.prodOfferingsService.deleteOffering(this.offeringInfo.offeringDetail._id) : this.prodOfferingsService.moveToArchive(this.offeringReferenceId);
        if (action) {
          action.subscribe((response) => {
            this.prodOfferingsService.hideSpinner();
            if (response) {
              this.prodOfferingsService.onChangeInOfferings();
              this.goBack();
            }
          }, (error) => {
            this.prodOfferingsService.hideSpinner();
          });
        }
      }
    })

  }

  getCreatedBy(createdByObj) {
    let value = null;
    if (createdByObj && createdByObj.currentName
      && createdByObj.currentName.names && createdByObj.currentName.names.length) {
      value = createdByObj.currentName.names.toString().replace(',', ' ');
    }

    return value;
  }

  getEmptyOffering() {
    this.offeringAction = 'create';
    this.offeringStatus = null;
    return {
      "offeringDetail": {
        // "name":"GPS-Test",
        "displayName": null,
        "description": null,
        "productReference": null,
        "productMultiplicityReference": {},
        "type": null,
        "multiMax": 0,
        "multiMin": 0,
        "singleSelectMultiplicity": false
      },
      "restrictions": [],
      "rules": [],
      "tasks": []
    }
  }

  getOfferingForm() {
    let offeringFormGroup: FormGroup = this.formBuilder.group({
      offeringDetail: this.createOfferingDetailsForm(this.offeringInfo.offeringDetail),
      incremental: this.addIncrematalForm(this.offeringInfo.incremental),
      isIncremental: new FormControl(this.isIncremental),
      restrictions: this.patchRestrictionsValue(this.restrictions),
      rules: this.patchRulesValue(this.offeringInfo.rules),
      tasks: this.patchTasksValue(this.offeringInfo.tasks),
      prodSubDetails: this.generateProductForm(this.offeringInfo.offeringDetail),
    });
    if (!this.isIncremental) {
      offeringFormGroup.get('incremental').disable();
    }
    return offeringFormGroup;
  }

  createOfferingDetailsForm(value?) {
    let offeringDetailsForm: FormGroup = this.formBuilder.group({
      displayName: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      productReference: new FormControl(null, Validators.required),
      productMultiplicityReference: new FormControl(''),
      type: new FormControl(null, Validators.required),
      multiMax: new FormControl(0, Validators.required),
      multiMin: new FormControl(0, Validators.required),
      singleSelectMultiplicity : new FormControl(false),
    });
    if (value) {
      offeringDetailsForm.patchValue(value);
    }
    return offeringDetailsForm;
  }

  addIncrematalForm(value?) {
    let incrementalForm = new FormGroup({
      type: new FormControl("Timespan", Validators.required),
      timeframe: new FormControl("Hours", Validators.required),
      incrementValue: new FormControl(0, Validators.required),
      maxIncrementValue: new FormControl(0, Validators.required),
      flexTotalValues: new FormControl(0, Validators.required),
      timeGuaranteed: new FormControl(0, Validators.required)
    });
    if (value) {
      incrementalForm.patchValue(value)
    }
    return incrementalForm;
  }

  patchRestrictionsValue(value?: any[]) {
    let restrictionsFormArray = this.formBuilder.array([]);
    if (value && value.length) {
      value.forEach((item) => {
        if (this.hiddenCriteriaTypes.indexOf(item.type) == -1) {
          restrictionsFormArray.push(this.addNewRestrictionForm(item));
          // restrictionsFormArray.push(new FormControl(item));
        }
      })
    }
    return restrictionsFormArray;
  }

  addNewRestrictionForm(value) {
    let restrictionInfo: FormGroup = this.formBuilder.group({
      values: this.patchRestrictionValues(value.values),
    });
    if (value && Object.keys(value).length) {
      Object.keys(value).forEach(key => {
        if (key != 'values') {
          restrictionInfo.addControl(key, new FormControl(value[key]));
        }
      });
    }

    return restrictionInfo;
  }

  patchRestrictionValues(values) {
    let valuesArray = this.formBuilder.array([]);
    if (values && values.length) {
      values.forEach(item => {
        (valuesArray as FormArray).push(this.ResValuesFormControls(item));
      });

    }

    return valuesArray;
  }

  ResValuesFormControls(item) {
    if (item) {
      if (typeof item == 'string' || typeof item == 'boolean' || typeof item == 'number') {
        return new FormControl(item, Validators.required);
      } else if (item && Array.isArray(item)) {
        let controlArray = new FormArray([], Validators.required);
        if (item.length) {
          item.forEach((val) => {
            controlArray.push(this.ResValuesFormControls(val));
          });
        }
        return controlArray;
      } else if (item && Object.keys(item)) {
        let controlObject = new FormGroup({}, Validators.required);
        if (Object.keys(item).length) {
          Object.keys(item).forEach(key => {
            controlObject.addControl(key, this.ResValuesFormControls(item[key]));
          });
        }
        return controlObject;
      }
    }
    return new FormControl('', Validators.required);
  }


  patchRulesValue(value?: any[]) {
    let rulesFormArray = this.formBuilder.array([]);
    if (value && value.length) {
      value.forEach((item) => {
        rulesFormArray.push(this.addRuleControls(item));
      })
    }

    return rulesFormArray;
  }

  addRuleControls(value?) {
    let ruleForm = this.addNewRulesControl();
    if (value) {
      ruleForm.get('rule').setValue(value.rule);
      if (value.offerings && value.offerings.length) {
        value.offerings.forEach(element => {
          (ruleForm.get('offerings') as FormArray).push(this.addRuleOfferingValue(element));
        });
      }
    }

    return ruleForm;
  }

  addNewRulesControl() {
    return this.formBuilder.group({
      rule: new FormControl('', Validators.required),
      offerings: this.formBuilder.array([], Validators.required)
    })
  }

  addRuleOfferingValue(value?) {

    return new FormGroup({
      displayName: new FormControl(value ? value.displayName : '', Validators.required),
      reference: new FormControl(value ? value.reference : '', Validators.required)
    }, Validators.required)

  }

  patchTasksValue(value?: any[]) {
    let tasksValue = this.formBuilder.array([]);
    if (value && value.length) {
      value.forEach((item) => {
        tasksValue.push(this.addTaskControl(item));
      })
    }

    return tasksValue;
  }

  addTaskControl(value?) {
    let taskControl = new FormControl(value ? value : '', Validators.required);
    return taskControl;
  }

  iconUploadPopup() {
    const dialogRef = this.dialog.open(IconUploadModalComponent, {
      disableClose: true,
      width: '40vw',
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.iconObj = data.iconUrl;
        this.iconSrc = data.iconUrl.iconName;
        this.iconSrcName = this.iconObj.iconDisplayName
      }
    })
  }
  calculatedValue() {
    return (this.offeringInfoForm.get('incremental').get('incrementValue').value * this.offeringInfoForm.get('incremental').get('maxIncrementValue').value + " Days");
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
