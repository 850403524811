import { Component, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FlexAlignDirective } from '@angular/flex-layout';
import { AbstractControl, ControlContainer, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service';

import { RestrictionBaseComponent } from '../../common/restriction-base.component';

@Component({
  selector: 'app-application-uitype-restriction',
  templateUrl: './application-uitype-restriction.component.html',
  styleUrls: ['./application-uitype-restriction.component.scss'],
})
export class ApplicationUitypeRestrictionComponent extends RestrictionBaseComponent {

  
  matFocused:any = [];
  isAppNotSelected: boolean;
  disablePoints: boolean;
  points: any;
  constructor(public prodOfferingsService : ProdOfferingsService) {
    super(prodOfferingsService);
    
    
  }

  addApplication () {
    this.isAppNotSelected = false;
    let values = this.parentForm.get('values').value;
    let lastValIndex = (values && values.length) ? values.length -1 : -1;
    if(lastValIndex > -1 && values[lastValIndex]) {
      (this.parentForm.get('values') as FormArray).push(new FormControl(null,Validators.required));
    }else{
      this.isAppNotSelected = true;
    }
  }

  removeApplication (index) {
    let values = this.parentForm.get('values').value;
    if(values[index] && values[index].replace(' ', '').toLowerCase() == 'benefitsbuilder'){
      this.points = null;
      if(this.parentForm.get('points')){
        this.parentForm.removeControl('points');
      }
    }
    (this.parentForm.get('values') as FormArray).removeAt(index)
  }

  matFocusedEvent(event,i,key){
    this.matFocused[i] = {};
    this.matFocused[i][key] = event;
  }

  isSelcetedApplication (type ,indexVal) {
    let flag = false;
    let values = this.parentForm.get('values').value;
    if( values &&values.length){
      values.forEach((element,index) => {
        if(element == type && indexVal != index) {
          flag = true;
        }
      });
    }

    return flag;
  }

  getApplicationsList () {
    console.log(this.restriction);
    this.points = this.parentForm && this.parentForm.get('points') ? this.parentForm.get('points').value : null;
    if(sessionStorage.getItem('OfferingApplications')){
      this.applicationsList = JSON.parse(sessionStorage.getItem('OfferingApplications'));
    }
    if(!this.applicationsList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('OfferingApplications').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          this.applicationsList = response[0]['values'];
          console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
    this.valueChangeEvent();
  }

  isAllowedToAddNewApplication () {
    let flag = false;
    let values = this.parentForm.get('values').value;
    if(!values || !values.length){
      flag = true;
    }else if(this.applicationsList && this.applicationsList.length){
      flag = this.applicationsList.length > values.length
    }
    return flag;
  }

  valueChangeEvent(){
    let newVal = this.parentForm &&  this.parentForm.get('values').value ? this.parentForm.get('values').value : null;
    this.disablePoints = false;
    this.isAppNotSelected = false;
    if(newVal && newVal.includes('BenefitsBuilder')){
      this.prodOfferingsService.bbRole.next([newVal]);
      if(!this.parentForm.get('points')){
        this.parentForm.addControl('points',new FormControl(0,Validators.required));
      }
      if( this.incrementalType == 'Amount'){
        console.log("offerting Type : ",this.offeringType);
        this.parentForm.get('points').setValue(1);
        this.disablePoints = true;
        this.parentForm.get('points').disable();
      }
      if(this.offeringType == 'Single' && !this.parentForm.get('points').value){
        this.parentForm.get('points').setValue(0);
      }
      if(this.offeringType != 'Single' && !this.parentForm.get('points').value){
        this.parentForm.get('points').setValue(1);
      }
      this.points = this.parentForm.get('points').value;
    }else if(newVal && !newVal.includes('BenefitsBuilder')){
      this.parentForm.removeControl('points');
      this.prodOfferingsService.bbRole.next(['']);
    }
  }

  pointsValChanged(event,i?,type?) {
    if(type){
      this.matFocused[i]={};
      this.matFocused[i][type] = false;
    }
    if(!this.parentForm.get('points')){
      this.parentForm.addControl('points', new FormControl(0,Validators.required));
    }
    this.parentForm.controls['points'].setValue(this.points);
  }
     
}
