import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from "@angular/forms";
import { ProdOfferingsService } from "../../services/product-offerings.service";


@Component({
    selector: 'app-restriction-base',
    template: '',
  })
export class RestrictionBaseComponent implements OnInit,OnChanges  {

    @Input() restriction: any;
    @Input() incrementalType : any;
    @Input() offeringType : any;
    @Input() disableEditOffering;
    @Input() parentForm:FormGroup;
    applicationsList = [];
    operatorsList = [];
    pointsList = [
        {value:0,displayValue:'Core'},
        {value:1,displayValue:1},
        {value:2,displayValue:2},
        {value:3,displayValue:3},
        {value:4,displayValue:4},
        {value:5,displayValue:5},
        {value:6,displayValue:6},
        {value:7,displayValue:7},
        {value:8,displayValue:8},
        {value:9,displayValue:9},
        {value:10,displayValue:10},
        
    ]
    complexRulesList:any = [];
    dropdownList = [];
    availableCountries = []
    availableCities:any = [];
    restrictionChangedValues

    @Output() remove: EventEmitter<any> = new EventEmitter();

    constructor(public prodOfferingsService : ProdOfferingsService){
        
        
    }

    ngOnInit(): void {
        this.getApplicationsList();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes && changes.incrementalType && changes.incrementalType.currentValue){
            this.incrementalType = changes.incrementalType.currentValue;
            this.valueChangeEvent();
        }
        if(changes && changes.offeringType && changes.offeringType.currentValue){
            this.offeringType = changes.offeringType.currentValue;
            this.valueChangeEvent();
        }
        if (this.offeringType == 'Single' && this.pointsList[0].displayValue != 'Core') {
            this.pointsList.splice(0, 0, { value: 0, displayValue: 'Core' });
        } else if (this.pointsList[0].displayValue == 'Core') {
            this.pointsList.splice(0, 1);
        }
    }

    //Implemented in child classes
    getApplicationsList () {
    }

    //Impleneted in child clases
    valueChangeEvent(){

    }

    //Implemented in child classes
    restrictionValueChange(event){
    }

    removeRestriction(type){
        this.remove.emit(type);
    }

    

   
    
}