import { Component, OnInit } from '@angular/core';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service';

import { RestrictionBaseComponent } from '../../common/restriction-base.component';

@Component({
  selector: 'app-dropdown-uitype-restriction',
  templateUrl: './dropdown-uitype-restriction.component.html',
  styleUrls: ['./dropdown-uitype-restriction.component.scss']
})
export class DropdownUitypeRestrictionComponent extends RestrictionBaseComponent implements OnInit {

 

  constructor(public prodOfferingsService : ProdOfferingsService) { 
    super(prodOfferingsService);
  }

  ngOnInit(): void {
    console.log(this.restriction.List);
    delete this.restriction.List;
    this.getDropDownValues();
  }

  getDropDownValues(){
    if(sessionStorage.getItem('HomeOwnerStatus')){
      this.dropdownList = JSON.parse(sessionStorage.getItem('HomeOwnerStatus'));
    }
    if(!this.dropdownList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('HomeOwnerStatus').subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        if(response && response[0]){
          this.dropdownList = response[0]['values'];
          console.log(response);
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
    
  }

}
