
<div fxLayout="row">
    <div fxFlex="15%"  class="menu-list-div">
        <div fxLayout="row" class="temp-icon-hidden-col">
        </div>
        <div fxLayout="row" class="temp-icon-col">
            <div fxFlex="100%" fxLayoutAlign="center center">
                <div class="temp-icon" [ngClass]="{'active-menu-item':(activeButton == 'template')}" (click)="activeButton = 'template';onSlection('templates')" >
                    <button >
                        <mat-icon>description</mat-icon>
                    </button >
                    <span >Templates</span>
                </div>
            </div>
        </div>
        <div fxLayout="row" class="temp-icon-col">
            <div fxFlex="100%" fxLayoutAlign="center center">
                <div class="prod-icon"  [ngClass]="{'active-menu-item':(activeButton == 'offering')}" (click)="activeButton = 'offering';onSlection('offerings')">
                    <button>
                        <img class="header-logo-img" src="../assets/icons/gift_icon_white.png" alt="" />
                    </button>
                    <span >Offerings</span>
                </div>
            </div>
        </div>
        <div fxLayout="row" class="backgroung-img-row">
        </div>
    </div>
    <div fxFlex="85%" fxFlexOffset="15%" class="menu-content-div">
        <router-outlet ></router-outlet> 
    </div>
</div>