import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

import { ProdOfferingsService } from '../../services/product-offerings.service';
import { ProdTemplatesService } from '../../services/product-templates.service';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';

@Component({
  selector: 'app-create-prod-template',
  templateUrl: './create-prod-template.component.html',
  styleUrls: ['./create-prod-template.component.scss'],
})
export class CreateProdTemplateComponent implements OnInit {

  btnConfig = {
    showCancelBtn:true,
    showSaveDraftBtn:true,
    showPublishBtn:true
  };
  availableOfferingsList = [];
  addedOfferingsList = [];
  tempType ='create';
  templateName:any;
  templateDescription:any;
  templateInfo: any;
  tempStatus: string;
  isDuplicateTempName = false

  constructor(public route: ActivatedRoute,
      public router: Router,
      public prodOfferingsService: ProdOfferingsService,
      public prodTemplatesService :ProdTemplatesService,
      private _snackBar: MatSnackBar,
      public dialog : MatDialog
  ){

  }

  ngOnInit(){ 
      console.log("CreateTemplateComponent loads");
      this.tempStatus  = sessionStorage.getItem("tempStatus");
      if(sessionStorage.getItem('templateInfo')){
        this.templateInfo = JSON.parse(sessionStorage.getItem('templateInfo'));
        this.templateName = this.templateInfo['name'];
        this.templateDescription = this.templateInfo['description'];
        this.addedOfferingsList = this.templateInfo['offerings']
      }
      if(sessionStorage.getItem('isDuplicate')){
        this.tempType = 'duplicate'
      }
  }

  goBack (event?) {
    if(this.tempType == 'duplicate'){
      this.router.navigate(['/view-template',this.templateInfo._id]);
    }else{
      this.router.navigate(['/prod-menu-list/product-templates']);
    }
  }

  changeActiveTempStatus () {
    this.prodTemplatesService.showSpinner();
      this.prodTemplatesService.moveTemplateToArchive( this.templateInfo['_id']).subscribe((response)=>{
        console.log(response);
        this.prodTemplatesService.hideSpinner();
        if(response){
          this.prodTemplatesService.onChangeInTemplates();
          this.goBack();
        }
      },(error)=>{
        this.prodTemplatesService.hideSpinner();
      });
  }

  cancel ($event?) {
    let dialogRef =this.dialog.open(WarningModelComponent,{
      data:{
        title:"Discard Changes",
        content:"Are you sure you want to discard the changes on this Template? You will lose all the changes that you have made.",
        actions:[
          {name:'Cancel',type:'close'},
          {name:'Discard',type:'discard'},
        ]
      },
      width:'32.74em'
    });

    dialogRef.afterClosed().subscribe((action) =>{
      if(action == 'discard'){
        this.goBack();
      }
    })
  }

  saveDraft (data) {
    let fiftyYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear()+50)).toJSON();
    this.isDuplicateTempName = false;
    data['fromDate']= fiftyYearsFromNow;
    this.prodTemplatesService.showSpinner();
    this.prodTemplatesService.createNewTemplate(data).subscribe((response)=>{
      this.prodTemplatesService.updateDraftTemplate(response['_id'],data).subscribe((response) =>{
        this.prodTemplatesService.hideSpinner();
        this.prodTemplatesService.onChangeInTemplates();
        this.tempType = null;
        this.goBack();
        console.log();
      },(error)=>{
        if(error.message && error.message.includes("Name must be unique")){
          this.isDuplicateTempName = true;
        }
        this.prodTemplatesService.hideSpinner();
      })
    },(error)=>{
      if(error.message && error.message.includes("Name must be unique")){
        this.isDuplicateTempName = true;
      }
      this.prodTemplatesService.hideSpinner();
    });  
  }

  publish (templateData) {
    this.prodTemplatesService.showSpinner();
    this.isDuplicateTempName = false;
    this.prodTemplatesService.ActivateTemplate(templateData).subscribe((response)=>{
      if(response){
        this.prodTemplatesService.hideSpinner();
        this.prodTemplatesService.onChangeInTemplates();
        this.prodTemplatesService.setPublishedTemplateStatus('true');
        this.tempType = null;
        this.goBack();
      }
    },(error)=>{
      this.prodTemplatesService.hideSpinner();
      this.prodTemplatesService.setPublishedTemplateStatus('false'); 
      if(error.message && error.message.includes("Name must be unique")){
        this.isDuplicateTempName = true;
      }else{
        this.tempType = null;
        this.goBack();
      }
     
    });
  }
  
}