<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{parentForm?.get('type').value}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!disableEditOffering" (click)="removeRestriction(parentForm?.get('type').value)">remove</mat-icon>
        </div>
    </div>
</div>
<div fxLayout="row wrap" class="resction_details_div" *ngIf="parentForm" [formGroup]="parentForm" >
    <ng-container formArrayName="values">
        <div fxFlex="100" *ngFor="let item of parentForm?.get('values')['controls']; let i = index; let fisrtItem = first; let lastItem = last;" >
            <div>
                <section class="example-section">
                    <mat-slide-toggle class="example-margin" [color]="'red'"  [formControlName]="i">
                        {{parentForm?.get('values').get(''+i).value == false ? 'No' : 'Yes'}}
                    </mat-slide-toggle>
                  </section>
            </div>
        </div>
    </ng-container>
    
</div>

