<div>
    <div class="templates_info_section" >
        <div class="template-info-gorup-alignment">
            <div fxLayout="row" fxLayoutAlign="start">
                <div fxFlex="20" fxLayoutAlign="start center">
                    <mat-icon >description</mat-icon>
                    <span class="offering-logo-lbl" fxLayoutAlign="start center">Templates</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" >
                <div fxFlex="60%" fxLayout="row" class="tab-row" fxLayoutGap="1.25em">
                    <div fxFlex="33%" >
                        <div class="tab-div active-tab"  [ngClass]="{'tab-div-active':(selectedTemplateName == 'Existing Templates')}" (click)="loadSelectedTemplates('Active')">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <div fxFlex="20%" class="tab-icon">
                                    <mat-icon>check_circle_outline</mat-icon>
                                </div>
                                <div fxFlex="80%" class="tab-name">
                                    <p >
                                        {{templatesCount.Active.displayName}}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="75%" class="tab-count" fxFlexOffset="20%">
                                    <p> {{templatesCount.Active.total}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  fxFlex="30%" >
                        <div class="tab-div draft-tab" [ngClass]="{'tab-div-active':(selectedTemplateName == 'Draft Templates')}"  (click)="loadSelectedTemplates('Draft')">
                            <div fxLayout="row">
                                <div fxFlex="25%" class="tab-icon">
                                    <img *ngIf="selectedTemplateName != 'Draft Templates'" class="" src="../assets/icons/draft_icon.png" alt="" />
                                    <img *ngIf="selectedTemplateName == 'Draft Templates'" class="" src="../assets/icons/draft_icon_white.png" alt="" />
                                </div>
                                <div fxFlex="75%" class="tab-name">
                                    <p >
                                        {{templatesCount.Draft.displayName}}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="75%" class="tab-count" fxFlexOffset="25%">
                                    <p>{{templatesCount.Draft.total}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  fxFlex="33%" >
                        <div class="tab-div archive-tab" [ngClass]="{'tab-div-active':(selectedTemplateName == 'Archived Templates')}"  (click)="loadSelectedTemplates('Archived')">
                            <div fxLayout="row">
                                <div fxFlex="20%" class="tab-icon">
                                    <img *ngIf="selectedTemplateName != 'Archived Templates'" class="" src="../assets/icons/archived_icon.png" alt="" />
                                    <img *ngIf="selectedTemplateName == 'Archived Templates'" class="" src="../assets/icons/archived_icon_white.png" alt="" />
                                </div>
                                <div fxFlex="80%" class="tab-name">
                                    <p >
                                        {{templatesCount.Archived.displayName}}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="row">
                                <div fxFlex="75%" class="tab-count" fxFlexOffset="20%">
                                    <p>{{templatesCount.Archived.total}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em">
                <div fxFlex="70%" fxLayout="row"  class="search_row" fxLayoutGap="1em">
                    <div fxFlex="27%" fxLayout="column" class="dropdown-field" fxLayoutAlign="center">
                        <mat-form-field appearance="outline" >
                            <mat-select [(value)]="templatesFilterVal" (openedChange)="matFocusedEvent($event)" (selectionChange)="filterOrSortTemplates();" disableOptionCentering="true">
                                <mat-option *ngFor="let option of templatesFilterOptions" [value]="option.value">{{ option.name + ' - '+ option.count}}</mat-option>
                              </mat-select>
                              <mat-icon *ngIf="!matSelectFocused" matSuffix>keyboard_arrow_down</mat-icon>
                              <mat-icon *ngIf="matSelectFocused" matSuffix>keyboard_arrow_up</mat-icon>
                          </mat-form-field>
                    </div>
                    <div fxFlex="55%" >
                        <div fxLayout="row" class="serch_input_row">
                            <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon  class="search_icon">search</mat-icon>
                            </div>
                            <div fxFlex="80%" fxLayoutAlign="start center">
                                <input class="search_input"  [(ngModel)]="searchOptions.searchText" matInput placeholder="Search Templates…"  #searchInput  (ngModelChange)="searchTemplates(); ">
                            </div>
                            <div fxFlex="10%" fxLayoutAlign="center center">
                                <mat-icon *ngIf="showSearchCloseIcon" class="close_icon" (click)="showSearchCloseIcon=false;searchOptions.searchText=null;searchTemplates();">close</mat-icon>
                            </div>
                        </div>
                        
                    </div>
                    <div fxFlex="6%" fxLayoutAlign="start center">
                        <div  class="filter-row-icons">
                            <mat-icon (click)="searchOptions.sortDir = searchOptions.sortDir == 'ASC' ? 'DESC' : 'ASC';filterOrSortTemplates();">sort</mat-icon>
                        </div>
                    </div>
                </div>
                <div fxFlex="28%" fxLayoutAlign="end start"  *ngIf="selectedTemplateName != 'Archived Templates'">
                    <div>
                        <button mat-stroked-button color="primary" (click)="createTemplate()" class="create_template_btn">
                            <mat-icon class="btn_icon">add</mat-icon>
                            Create Template
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="showSearchCloseIcon"  fxLayout="row" class="template-list-header-row" fxLayoutAlign="start center">
                <div fxFlex="55%">
                    <span class="results_count_span"> {{(searchCount && searchCount != '0') ? searchCount : 'No'}} Results</span>
                    <span> found</span>
                </div>
            </div>
            <div *ngIf="!showSearchCloseIcon" fxLayout="row" class="template-list-header-row" fxLayoutAlign="start center">
                <div fxFlex="50%">
                    <span>Template Name</span>
                </div>
                <div fxFlex="18%">
                   <span>Offerings Available</span> 
                </div>
                <div fxFlex="15%">
                    <span>Created by</span> 
                </div>
                <div fxFlexOffset="2%" fxFlex="11%">
                    <span>Created on</span> 
                </div>
            </div>
        </div>
        
    </div>
    <div >
        <div class="template-gorup-alignment" >
            <div class="template_lists_section"  >
                <div class="template_list_div" *ngFor="let template of templatesData?.selectedTemplates;let last = last;"  style="cursor: pointer;position: relative;">
                    <mat-card fxLayout="row" fxLayoutAlign="start center" class="template-list-card-row">
                        <div fxFlex="50%" (click)="goToTemplate(template._id)">
                            <span class="temp-name-span"> {{template.name}} </span>
                        </div>
                        <div fxFlex="18%" (click)="goToTemplate(template._id)">
                            <span class="off-count-span" *ngIf="template.offerings && template.offerings.length">{{template.offerings.length +" Offering(s)"}}</span>
                        </div>
                        <div fxFlex="15%" (click)="goToTemplate(template._id)">
                            <span class="temp-addby-span">{{getCreatedBy(template.createdBy)}}</span>
                        </div>
                        <div fxFlexOffset="2%" fxFlex="11%" (click)="goToTemplate(template._id)">
                            <span class="temp-addby-span">{{template.createdAt | date: 'YYYY-MM-dd'}}</span>
                        </div>
                        <div *ngIf="selectedTemplateName == 'Archived Templates'" fxFlex="4%" fxLayoutAlign="end" class="restore-icon-div-column">
                            <div class="restore-icon-div">
                                <mat-icon (click)="restoreArchivedTempToDraft(template._id)"  #tooltip="matTooltip"
                                matTooltip="Restore Template"
                                [matTooltipPosition]="'below'"
                                >settings_backup_restore</mat-icon>
                            </div>
                        </div>
                    </mat-card>
                   
                </div>
                
            </div>  
        </div>
        <app-footer></app-footer>
    </div>
</div>



