<div class="row" class="header-toolbar" *ngIf="!dialogData || !dialogData.referenceId">
    <div fxFlex="5%">
        <a class="back-btn-icon" aria-label="Example icon-button with menu icon" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </a>
    </div>
    <div fxFlex="70%">
        <div fxFlex="93%">
        <span class="temp-header">Offering :
            </span>
            <span class="temp-name-span">
                &nbsp;{{offeringName}}
            </span>

        </div>
    </div>
    <div fxFlex="5%" fxFlexOffset="5%" fxLayoutAlign="end">
        <button *ngIf="offeringStatus && offeringStatus != 'Archived'" (click)="editOffering('edit')" mat-icon-button class="toolbar-right-icon favorite-icon"  aria-label="Example icon-button with heart icon">
            <mat-icon class="mat-card-icons">edit</mat-icon>
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end">
        <button *ngIf="offeringStatus && offeringStatus != 'Archived' && offeringInfo?.offeringDetail && !offeringInfo?.offeringDetail.isCashOutOffering" (click)="editOffering('duplicate')" mat-icon-button class="toolbar-right-icon favorite-icon" aria-label="Example icon-button with share icon">
            <img class="offering-logo-img" src="../assets/icons/material-content-copy.svg" alt="" />
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end">
        <button *ngIf="offeringStatus && offeringStatus != 'Archived' && offeringInfo?.offeringDetail && !offeringInfo?.offeringDetail.isCashOutOffering" (click)="openDeleteModel()" mat-icon-button  class="toolbar-right-icon" aria-label=" icon-button with share icon">
            <mat-icon class="mat-card-icons" >delete</mat-icon>
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end ">
         <button *ngIf="offeringStatus && offeringStatus == 'Archived'" (click)="restoreArchivedOffering()"  mat-icon-button class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <mat-icon>settings_backup_restore</mat-icon>
        </button>
        <!-- <button mat-icon-button class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <img class="offering-logo-img" src="../assets/icons/material-file-download.svg" alt="" />
        </button> -->
    </div>
</div>
<div class="row" *ngIf="dialogData && dialogData.referenceId">
    <div fxFlex="80%">
        <div fxFlex="93%">
        <span class="temp-header">Offering :
            </span>
            <span class="temp-name-span">
                &nbsp;{{offeringName}}
            </span>
        </div>
    </div>
    <div fxFlex="20%" fxLayoutAlign="end">
        <mat-icon (click)="closeDialog('close')" class="">close</mat-icon>
    </div>
</div>
<div class="" [ngClass]="{'offering_content':(!dialogData || !dialogData.referenceId), 'offering_content_model':(dialogData && dialogData.referenceId) }">
    <div fxLayout="row" fxLayoutAlign="start"  style="cursor: pointer;">
        <div fxFlex="100%">
            <mat-card class="offering-card">
                <div fxLayout="row" class="" fxLayoutAlign="center center">
                    <div fxFlex="10%" fxLayoutAlign="center center">
                        <span>
                            <img class="icon-offering" src={{offeringIcon}} alt=""/>
                        </span>
                    </div>
                    <div fxFlex="90%">
                        <!-- <div fxLayout="row" class="offering_info" fxLayoutAlign="start center">
                            <div fxFlex="100%">
                               <span class="offering-name"> displayName</span>
                            </div>
                        </div> -->
                        <div fxLayout="row" class="offering_info">
                            <div fxFlex="96%" >
                                <p class="offering-desription">
                                    {{offeringDescription}}
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row" class="offering_info offering_status_info"  fxLayoutAlign="start center">
                            <div fxFlex="23%">
                                <div class="prod-name" *ngIf="offeringInfo?.offeringDetail && offeringInfo?.offeringDetail.productReference && offeringInfo?.offeringDetail.productReference">
                                    <span >{{offeringInfo?.offeringDetail && offeringInfo?.offeringDetail.productReference ? offeringInfo?.offeringDetail.productReference.displayName: ""}}</span>
                                </div>
                             </div>
                             <div fxFlex="18%">
                                 <div fxLayot="row" fxLayoutAlign="start center">
                                    <div  #tooltip="matTooltip" matTooltip="{{categoryInfo.list.join('\n')}}" [matTooltipDisabled]="(!categoryInfo.count || categoryInfo.count <= 1)" matTooltipClass="tooltip-list" [matTooltipPosition]="'below'" fxLayoutAlign="start center">
                                        <div class="prod-status" *ngIf="categoryInfo.displayName" [ngStyle]="{'border-color':categoryInfo.colorCode}">
                                            <span class="prod-status-color" [ngStyle]="{'background-color':categoryInfo.colorCode}"></span>
                                            <span class="prod-status-type">{{categoryInfo.displayName}}</span>
                                        </div>
                                        <span *ngIf="categoryInfo.count > 1" [ngStyle]="{'border-color':categoryInfo.colorCode}" class="prod-status-count">+{{categoryInfo.count -1}}</span>
                                    </div>
                                 </div>
                             </div>
                             <div fxFlex="19%">
                                <div  fxLayout="row" fxLayoutAlign="center center">
                                    <span class="prod-user-icon"><img src="../assets/icons/grid_icon.png" alt=""/></span>
                                    <span class="prod-user-name"> {{productType}}</span>
                                </div>
                            </div>
                            <div fxFlex="10%">
                                <div  fxLayout="row" *ngIf="points && productType !='Variable'" fxLayoutAlign="center center">
                                    <span class="prod-user-icon"><img src="../assets/icons/badge_icon.png" alt=""/></span>
                                    <span class="prod-user-name">{{points}}</span>
                                </div>
                            </div>
                            <div fxFlex="15%">
                                <div  fxLayout="row" fxLayoutAlign="center center" *ngIf="offeringInfo?.offeringDetail && offeringInfo?.offeringDetail.createdBy">
                                    <span class="prod-user-icon"><mat-icon>account_circle</mat-icon></span>
                                    <span class="prod-user-name">{{getCreatedBy(offeringInfo.offeringDetail.createdBy)}}</span>
                                </div>
                            </div>
                             <div fxFlex="15%" fxLayoutAlign="center center">
                                <div fxLayot="row" fxLayoutAlign="start center" *ngIf="offeringInfo?.offeringDetail && offeringInfo?.offeringDetail.createdAt">
                                    <span class="prod-time-icon"> <mat-icon>access_time</mat-icon></span>
                                    <span class="prod-time-info"> {{offeringInfo?.offeringDetail.createdAt | date: 'YYYY-MM-dd'}}</span>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <mat-card class="offering_logic_card" *ngIf="productList || domesticProductList">
        <div fxLayout="row">
            <div fxFlex="50" fxLayoutAlign="start center">
                <p class="offering_logic_card_title">Legacy Product Mapping</p>
            </div>
        </div>
        <div fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayoutGap.xs="0px" class="margin-right">
            <div fxFlex="50%" class="product_mapping" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <div>
                    <h5 class="product_details_heading">
                        <mat-icon>public</mat-icon>International Product Details
                    </h5>
                    <div class="product_details_form">
                        <span> {{productList? productList: 'None'}} </span>
                    </div>
                    <div>
                        <mat-icon class="arrow_right">subdirectory_arrow_right</mat-icon>
                        <span> {{InternationalSubProduct? InternationalSubProduct: 'None'}} </span>
                    </div>
                </div>
            </div>
            <div fxFlex="50%" class="product_mapping" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                <div>
                    <h5 class="product_details_heading">
                        <mat-icon>local_shipping</mat-icon>US Domestic Product Details
                    </h5>
                    <div class="product_details_form">
                        <span> {{domesticProductList? domesticProductList: 'None'}} </span>
                    </div>
                    <div>
                        <mat-icon class="arrow_right">subdirectory_arrow_right</mat-icon>
                        <span> {{domesticSubProduct? domesticSubProduct: 'None' }} </span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <div fxLayout="row" >
        <div fxFlex="100">
            <mat-card class="offering_logic_card" *ngIf="(restrictions && restrictions.length) || (offeringInfo && offeringInfo?.rules && offeringInfo?.rules.length) || (offeringInfo && offeringInfo?.tasks && offeringInfo?.tasks.length)">
                <div fxLayout="row">
                    <p class="offering_logic_card_title">Offering logic</p>
                </div>
                <div fxLayout="row" class="restrictions_info" *ngIf="restrictions && restrictions.length">
                    <div fxFlex="100">
                        <div fxLayout="row" *ngIf="!dialogData || !dialogData.referenceId">
                            <span class="offering_logic_restrictions_title">Defined Restriction(s)</span>
                        </div>
                        <div fxLayout="row wrap" fxLayoutGap="1%">
                            <div  fxFlex="{{(firstItem || (lastItem && !evenItem) ) ? '100%' : '49%'}}" fxFlexOrder="restriction.order" *ngFor="let restriction of restrictions;let firstItem = first;let lastItem = last ; let evenItem = even;" class="restrictions_info_div" [ngClass]="{'restrictions_model_backgorund': !firstItem}">
                                <div fxLayout="row" >
                                    <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
                                </div>
                                <div fxLayout="row" class="resction_details_div" *ngIf="restriction.uiType == 'application'">
                                    <div fxFlex="50">
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Application</span>
                                        </div>
                                        <div fxLayout="row" [hidden]="!restriction.values" class="resction_details_row"  *ngFor="let value of restriction.values">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{getRestrictionValDisplayName(restriction.uiType,value)}}</span>
                                        </div>
                                    </div>
                                    <div fxFlex="50" *ngIf="points && productType !='Variable'">
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Points</span>
                                        </div>
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{points}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" class="resction_details_div" *ngIf="restriction.uiType == 'operator'">
                                    <div fxFlex="100">
                                        <div fxFlex="50" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Owner</span>
                                        </div>
                                        <div fxFlex="50" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Number</span>
                                        </div>
                                    </div>
                                    <div fxFlex="100"  *ngFor="let item of restriction.values">
                                        <div fxFlex="50"  class="resction_details_row"  >
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{getRestrictionValDisplayName(restriction.uiType,item.operator)}}</span>
                                        </div>
                                        <div fxFlex="50" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{item.value}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" class="resction_details_div" *ngIf="restriction.uiType == 'location'">
                                    <div fxFlex="100">
                                        <div fxFlex="50" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">{{restriction && restriction.type ? restriction.type.split(' ')[0] : ''}} Country</span>
                                        </div>
                                        <div fxFlex="50" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">City/State</span>
                                        </div>
                                    </div>
                                    <div fxFlex="100"  *ngFor="let item of restriction.values">
                                        <div fxFlex="50"  class="resction_details_row"  >
                                            <img src="{{'https://www.countryflags.io'+item.value+'/shiny/64.png'}}" class="country_flag">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{item.country}}</span>
                                        </div>
                                        <div fxFlex="50" class="resction_details_row" *ngIf="item.cityStPr">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val" *ngFor="let city of  item.cityStPr">{{city.city}}/ {{city.state}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" class="resction_details_div" *ngIf="restriction.uiType == 'complexRule'">
                                    <div fxFlex="100">
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Restriction Criteria</span>
                                        </div>
                                    </div>
                                    <div fxFlex="100">
                                        <div fxLayout="row" [hidden]="!restriction.values" class="resction_details_row"  *ngFor="let value of restriction.values">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{value.displayName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" class="resction_details_div" *ngIf="restriction.uiType == 'dropdown'">
                                    <div fxFlex="100">
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Status</span>
                                        </div>
                                    </div>
                                    <div fxFlex="100">
                                        <div fxLayout="row" [hidden]="!restriction.values" class="resction_details_row"  *ngFor="let value of restriction.values">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{getRestrictionValDisplayName(restriction.uiType,value)}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" class="resction_details_div" *ngIf="restriction.uiType == 'toggle'">
                                    <div fxFlex="100">
                                        <div fxLayout="row" [hidden]="!restriction.values" class="resction_details_row"  *ngFor="let value of restriction.values">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{value == false ? 'No' : 'Yes'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div fxLayout="row" class="resction_details_div" *ngIf="restriction.uiType == 'dropdown'">
                                    <div fxFlex="50">
                                        <div fxLayout="row" [hidden]="!restriction.values" class="resction_details_row"  *ngFor="let value of restriction.values">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{value.value == false ? 'No' : 'Yes'}}</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row"  class="restrictions_info" *ngIf="offeringInfo && offeringInfo?.rules && offeringInfo?.rules.length">
                    <div fxFlex="100">
                        <div fxLayout="row" *ngIf="!dialogData || !dialogData.referenceId">
                            <span class="offering_logic_restrictions_title">Defined Rule(s)</span>
                        </div>
                        <div fxLayout="row wrap" >
                            <div fxFlex="100" class="restrictions_info_div" >
                                <div fxLayout="row " class="resction_details_div" *ngFor="let rule of offeringInfo?.rules;let i = index;">
                                    <div fxFlex="50">
                                        <div fxLayout="row wrap" class="resction_details_row">
                                            <div fxFlex="100">
                                                <div  fxFlex="50">
                                                    <span fxLayout="row" class="resction_info_div_subtitle">Rule #{{i+1}}</span>
                                                </div>
                                                <div  fxFlex="50">
                                                    <span fxLayout="row" class="resction_info_div_subtitle">Linked Offering</span>
                                                </div>
                                            </div>
                                            <div fxFlex="100">
                                                <div fxFlex="50">
                                                    <span fxLayout="row" class="resction_info_div_subtitle_val">{{rule.rule}}</span>
                                                </div>
                                                <div fxFlex="50">
                                                    <!-- {{ rule.offerings[0].displayName}} -->
                                                    <div *ngFor="let offering of rule?.offerings">
                                                        <span fxLayout="row" class="resction_info_div_subtitle_val" >{{offering.displayName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" class="restrictions_info" *ngIf="offeringInfo && offeringInfo?.tasks && offeringInfo?.tasks.length">
                    <div fxFlex="100">
                        <div fxLayout="row" *ngIf="!dialogData || !dialogData.referenceId">
                            <span class="offering_logic_restrictions_title">Defined Task(s)</span>
                        </div>
                        <div fxLayout="row" >
                            <div fxFlex="100" class="restrictions_info_div">
                                <div fxLayout="row wrap" class="resction_details_div" *ngFor="let task of offeringInfo?.tasks;let j =index;">
                                    <div fxFlex="100">
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle">Task #{{j+1}}</span>
                                        </div>
                                        <div fxLayout="row" class="resction_details_row">
                                            <span fxLayout="row" class="resction_info_div_subtitle_val">{{task}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="asso_temp_row" *ngIf="offeringStatus == 'Active'&& (!dialogData || !dialogData.referenceId)">
        <div fxLayout="row">
            <div fxFlex="50%" fxLayoutAlign="start center">
                <span class="asso_temp_title">Associated Template(s) - {{associatedTemplates.length}}</span>
            </div>
            <div fxFlex="15%"  *ngIf="!showSearchInput" fxFlexOffset="{{!showSearchInput ? '28%' : '0%'}}"  fxLayout="column" class="dropdown-field" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" >
                    <mat-select [(ngModel)]="tempTypeVal"  disableOptionCentering="true">
                        <mat-option  *ngFor="let option of templatesTypesOptions" [value]="option">{{ option}}</mat-option>
                      </mat-select>
                   <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                </mat-form-field>
            </div>
            <div fxFlex="{{!showSearchInput ? '5%' : '50%'}}" fxFlexOffset="{{!showSearchInput ? '1%' : '0%'}}" fxLayout="row" [ngClass]="{'serch_input_row_selected' : showSearchInput}">
                <div fxFlex="{{!showSearchInput ? '100%' : '10%'}}"  fxLayout="column" (click)="showSearchInput = !showSearchInput" fxLayoutAlign="center center">
                    <div [ngClass]="{'serch_input_row' : !showSearchInput}">
                        <mat-icon  class="search_icon">search</mat-icon>
                    </div>
                </div>
                <div *ngIf="showSearchInput" fxFlex="90%" fxLayoutAlign="start center">
                    <input class="search_input" matInput placeholder="Search Templates…" [(ngModel)]="searchText">
                </div>
            </div>
        </div>
        <div fxLayout="row wrap" >
            <ng-container  *ngFor="let template of associatedTemplates">
                <div fxFlex="100%" *ngIf="tempTypeVal.includes(template.status)" [ngStyle]="{'display' : showSearchInput && searchText &&  !template.name.toLowerCase().includes(searchText.toLowerCase())? 'none' : 'inherit'}" fxLayout="column" class="asso_temp_card_div">
                    <mat-card >
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="50%" class="asso_temp_name">
                                <span >
                                    {{template.name}}
                                </span>
                            </div>
                            <div fxFlex="15%" fxLayoutAlign="center center">
                                <span class="asso_temp_type">{{template.status}}</span>
                            </div>
                            <div fxFlex="15%"  fxLayoutAlign="center center">
                                <span *ngIf="template.offerings" class="asso_temp_offerings_count">{{template.offerings.length}} offering(s)</span>
                            </div>
                            <div fxFlex="15%" class="asso_temp_created" fxLayoutAlign="center center">
                                <span>{{getCreatedBy(template.createdBy)}}</span>
                            </div>
                            <div fxFlex="15%" class="asso_temp_created" fxLayoutAlign="center center">
                                <span>{{template.createdAt  | date: 'YYYY-MM-dd'}}</span>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </ng-container>

        </div>
    </div>
</div>
<div fxLayout="row"  *ngIf="!dialogData || !dialogData.referenceId">
    <app-footer></app-footer>
</div>
<mat-dialog-actions align="end" *ngIf="dialogData && dialogData.referenceId">
    <button mat-button class="mat-dialog-close" (click)="closeDialog('close')"  >Close</button>
</mat-dialog-actions>

