import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { response } from 'express';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service'

import { RestrictionBaseComponent } from '../../common/restriction-base.component';

@Component({
  selector: 'app-complexrule-uitype-restriction',
  templateUrl: './complexrule-uitype-restriction.component.html',
  styleUrls: ['./complexrule-uitype-restriction.component.scss']
})
export class ComplexruleUitypeRestrictionComponent extends RestrictionBaseComponent implements OnInit {
  lastCriteriaNotFound: boolean;
  matFocused = [];
 

  constructor(public prodOfferingsService : ProdOfferingsService) { 
    super(prodOfferingsService);
  }

  ngOnInit() {
    console.log(this.parentForm);
    delete this.restriction.List ;
    this.getComplexCriteriaList();
  }

  print(val){
    console.log(val);
  }

  getComplexCriteriaList(){
    if(!this.complexRulesList.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getComplexCriteriaList().subscribe((response)=>{
        this.prodOfferingsService.hideSpinner();
        console.log(response);
        if(response){
          this.complexRulesList = response;
        }
      },(error)=>{
        this.prodOfferingsService.hideSpinner();
      })
    }
    
  }

  addComplexRestriction () {
    let newRes = new FormGroup({
      "_id":new FormControl(null,Validators.required),
      "displayName": new FormControl(null,Validators.required)
    },Validators.required)
    let values  = this.parentForm.get('values').value;
    let lastValIndex = (values && values.length) ? values.length -1 : -1;
    this.lastCriteriaNotFound = false;
    if(lastValIndex > -1 && values[lastValIndex]._id) {
      (this.parentForm.get('values') as FormArray).push(newRes);
    }else{
      this.lastCriteriaNotFound = true;
    }
  }

  removeComplexRestriction (index) {
    (this.parentForm.get('values') as FormArray).removeAt(index);
  }

  getTheDescription(Index) {
    let description = '';
    let values  = this.parentForm.get('values').value;
    let id = values && values[Index] ? values[Index]._id : null;
    if(id && this.complexRulesList && this.complexRulesList.length){
      this.complexRulesList.forEach(element => {
        if(element._id == id) {
          description = element.description
        }
      });
    }

    return description;
  }

  addNewComplexresIsAlwd () {
    let flag = false;
    let values  = this.parentForm.get('values').value;
    if(!values || !values.length){
      flag = true;
    }else if(this.complexRulesList && this.complexRulesList.length){
      flag = this.complexRulesList.length > values.length
    }
    return flag;
  }

  matFocusedEvent(event,i,key){
    this.matFocused[i] = {};
    this.matFocused[i][key] = event;
  }

  selectedComplexRes(item,Index,type?){
    if(type){
      this.matFocused[Index] = {};
      this.matFocused[Index][type] = false;
    }
    this.parentForm.get('values').get(''+Index).setValue({
      "_id": item._id,
      "displayName": item.name
    })
  }

  disableResOption(item){
    let flag = false;
    let values  = this.parentForm.get('values').value;
    if(values && values.length){
      values.forEach(element => {
        if(element._id == item._id) {
          flag = true;
        }
      });
    }

    return flag;
  }
}
